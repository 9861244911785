.joinPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0 20px;
  min-height: calc(100vh - 90px);
}
.joinPage p {
  font-size: 14px;
}
.joinPageMobile {
  min-height: 100vh;
}
.verify {
  background: #FFE0C7;
}
.joinPageMobile {
  padding: 30px 0;
}
.joinPageMobile p {
  margin-left: 10px;
  margin-right: 10px;
}
.JoinScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Welcome screen*/
.joinFormContainer {
  width: 300px;
}
.or {
  font-size: 14px;
  color: #454452;
  margin: 20px 0;
}
.JoinScreenTitle {
  color: #245995;
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  margin: 30px -50px;
}
.JoinScreenTitleMobile {
  font-size: 24px;
  line-height: 29px;
  margin: 30px 0;
}
.button {
  margin-top: 12px;
}

/*Join Form*/
.joinForm {
  width: 300px;
}
.joinFormMobile {
  margin-top: 33px;
}
.formItems>div {
  margin-bottom: 36px;
}
.formItems>div:last-child {
  margin: 0;
}
.submitButton {
  max-width: 230px;
  margin: 36px auto 0;
}
.joinFooter {
  margin: 0;
  color: #5c5b66;
  font-size: 14px;
  line-height: 16px;
}
.checkBoxContainer {
  text-align: start;
  margin-top: 36px;
}
.checkBoxContainer label {
  margin: 0;
}
.checkBoxContainer label>span:first-child {
  margin-right: 9px;
  padding: 0;
}
.checkBoxLabel {
  font-size: 14px;
  line-height: 21.7px;
  color: #5c5b66;
}
.joinPage .modalFooter {
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #454452;
}

/*Registration Form*/
.registrationForm {
  text-align: left;
  width: 300px;
}
.registrationForm>div {
  margin-bottom: 36px;
}
.registrationForm>div:last-child {
  margin: 0;
}
.registrationForm .formItems {
  margin-bottom: 36px; 
}
.registrationFormMobile {
  margin-top: 33px;
}
.joinError {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto 20px;
  line-height: 1em;
}



.verificationContainer {
  margin-top: 27px;
}
.verificationContainerMobile {
  margin-top: 8px;
}
.verificationContainer h2 {
  margin: 0 0 37px;
  color: #245995;
  font-weight: bold;
  font-size: 38px;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.verificationContainerMobile h2 {
  margin: 0 0 24px;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.verificationContainer h3 {
  margin: 0 0 8px;
  color: #454452;
  font-weight: 400;
  line-height: 33px;
  font-size: 20px;
}
.verificationContainerMobile h3 {
  margin: 0 auto 62px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.verificationContainer p {
  margin: 40px auto 0;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 209px;
}
.verificationContainer p:last-child {
  margin: 8px auto 0;
}
/* p.verificationDescription {
  height: 53px;
  margin: 40px 0;
  color: #454452;
  font-size: 20px;
  max-width: fit-content;
  font-weight: 400;
  line-height: 26px;
}
.verificationContainerMobile p.verificationDescription {
  margin: 42px auto 63px;
  color: #454452;
  font-size: 18px;
  line-height: 23.6px;
  max-width: 220px;
} */