// colors that are being used
$orange: #f58533;
$white: #FFF;
$grey: #626262;
$grey_one: #d5d5d5;
$blue: #29649f;
$blue_one: #132e53;
$blue_two: #20395c;

.sliderContainer {
  position: relative;

  .image {
    background-image: url(../../images/slider_img.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    @media only screen and (max-width: 767px) {
      min-height: 200px;
    }
  }
  .sliderText {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    color: rgb(98, 98, 98);

    @media only screen and (max-width: 767px) {
      position: relative;
      padding: 20px 0;
    }

    h1 {
      max-width: 875px;
      width: 100%;
      margin: 0 auto 27px;

      @media only screen and (max-width: 1023px) {
        margin-bottom: 8px;
      }
    }

    .textContainer {
      max-width: 1120px;
      width: 100%;
      margin: 0 auto;
      
      p {
        line-height: 24px;

        @media only screen and (max-width: 1023px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.info-container {
  background: $orange;
  margin-bottom: 80px;

  @media only screen and (max-width: 639px) {
    margin-bottom: 30px;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 639px) {
      display: block;
    }
  }
}

.home-services {
  h1 {
    max-width: 920px;
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;
  }

  .services-inner {
    display: flex;
    padding-bottom: 55px;

    @media only screen and (max-width: 1023px) {
      display: block;
      padding-bottom: 20px;
    }

    .services {
      margin-right: 15px;
      flex: 1;

      @media only screen and (max-width: 1023px) {
        margin-bottom: 20px;
        margin-right: 0;
      }

      &:last-child {
        margin-left: 15px;
        margin-right: 0;

        @media only screen and (max-width: 1023px) {
          margin-left: 0;
        }
      }

      .header {
        display: flex;
        align-items: flex-start;
        padding-bottom: 23px;
        min-height: 122px;

        @media only screen and (max-width: 1199px) {
          min-height: 120px;
        }

        @media only screen and (max-width: 1023px) {
          min-height: auto;
        }

        @media only screen and (max-width: 767px) {
          display: block;
        }

        .heading {
          font-size: 30px;
          color: $blue_two;
          font-weight: 700;
          display: flex;
          align-items: center;
          margin-right: 17px;

          @media only screen and (max-width: 1199px) {
            font-size: 24px;
          }

          &:before {
            content: "";
            display: inline-flex;
            height: 60px;
            width: 10px;
            background: $orange;
            margin-right: 17px;

            @media only screen and (max-width: 1199px) {
              height: 40px;
            }
          }
        }
      }

      .body {
        font-size: 24px;
        line-height: 26px;
        color: $grey;
        padding-top: 21px;

        @media only screen and (max-width: 1199px) {
          font-size: 20px;
          padding-top: 10px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: normal;
        }
      }
    }

    .services-detail {
      padding: 40px 30px;
      background: $orange;
      border-radius: 4px;
      min-height: calc(100% - 122px);

      @media only screen and (max-width: 767px) {
        padding: 15px;
      }

      .serviceTile {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .account {
      .services-detail {
        background: $blue_one;
      }
    }
  }
}

.get-started {
  border: 4px solid $grey_one;
  border-radius: 6px;
  padding: 30px 75px;
  text-align: center;
  color: $blue_two;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 60px;

  @media only screen and (max-width: 1023px) {
    padding: 15px;
    font-size: 30px;
    line-height: normal;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 20px;
  }
  
  .text {
    padding-bottom: 19px;
  }

  .btn-outline {
    border-color: $orange;
    border-width: 2px;
    color: $orange;
    font-size: 20px;
    background: #FFF;
    text-transform: uppercase;
    padding: 12px 38px;

    &:hover {
      background: #FFF;
    }

    &:focus {
      color: $orange;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  .inner-col {
    padding: 0 15px;
    max-width: 33.333333%;
    width: 100%;
  }
}

.get-access {
  h1 {
    color: $blue_two;
  }

  .inner-col {
    margin-bottom: 30px;
    
    @media only screen and (max-width: 1023px) {
      max-width: 50%;
    }

    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }

    &:nth-child(2n+1) {
      .inner {
        background: #fef4ed;
      }
    }

    &:nth-child(2n) {
      .inner {
        .heading {
          &:after {
            background: #fef4ed;
          }
        }
      }
    }

    .inner {
      background: $white;
      box-shadow: 0 0 10px rgba(0,0,0,.3);
      padding: 18px 16px 30px;
      text-align: center;
      height: 100%;  

      .number {
        height: 55px;
        width: 55px;
        background: #eaeaea;
        color: $blue_two;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-bottom: 5px;
        font-weight: 700;       
      }

      img {
        max-height: 102px;
        margin-bottom: 30px;
      }

      .heading {
        color: $blue_two;
        font-size: 20px;
        font-weight: 700;

        &:after {
          display: block;
          content: "";
          height: 2px;
          width: 130px;
          background: $white;
          margin: 15px auto;
        }
      }

      .text {
        max-width: 310px;
        width: 100%;
        margin: 0 auto;
        color: $grey;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }
}