.aboutContainer {
  background: url("../../images/about_bg.jpg") no-repeat center;
  background-size: cover;
  min-height: 690px;
  display: flex;
  align-items: center;
  padding: 30px 0;
  margin-bottom: -20px;

  p {
    color: #626262;
    line-height: 24px;

    a {
      color: #f58533;
      text-transform: initial;
    }
  }

  .body {
    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.heading-with-line {
  font-size: 30px;
  font-weight: 900;
  color: #20395c;
  padding-bottom: 25px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    font-size: 24px;
  }
  
  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }

  &:before {
    content: "";
    height: 26px;
    width: 7px;
    background: #f58533;
    display: block;
    margin-right: 20px;
  }

  span {
    margin: 0 7px;
  }
}