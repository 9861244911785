.tileWrapper {
  background: #132e53;
  max-width: 272px;
  width: 100%;
  padding: 25px 15px;
  
  @media only screen and (max-width: 1199px) {
    max-width: calc(100vw / 4.4);
  }

  @media only screen and (max-width: 639px) {
    display: flex;
    max-width: none;
    align-items: center;
    margin-bottom: 7px;
    padding: 12px 15px;
  }

  .img {
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 18px;

    @media only screen and (max-width: 767px) {
      height: 40px;
      width: 40px;
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 639px) {
      margin: 0;
      height: 24px;
      width: 24px;
    }
  }

  .text {
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    max-width: 115px;
    margin: 0 auto;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 639px) {
      max-width: none;
      text-align: left;
      margin: 0 0 0 15px;
    }
  }
}