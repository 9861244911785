.serviceTile {
  display: flex;
  padding-bottom: 20px;

  .number {
    height: 60px;
    width: 75px;
    background: #f5f5f5;
    color: #626262;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1023px) {
      height: 50px;
      width: 60px;
      font-size: 20px;
    }
  }

  .text {
    font-size: 17px;
    color: #ffffff;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 30px;
    border-bottom: 1px solid #ffffff;

    @media only screen and (max-width: 1023px) {
      font-size: 14px;
      margin-left: 10px;
    }

    @media only screen and (max-width: 767px) {
      padding-bottom: 10px;
    }
  }
}