@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
.Logo_logoMobile__3gLXH svg {
  height: 30px;
}
.Logo_logoMobile__3gLXH img {
  width: 180px;
  height: 43px;
}
.Logo_mobile__2C4yc svg {
  height: auto;
}
.Logo_mobile__2C4yc svg rect:nth-child(3){
  fill: #1D1F52;
}
.Logo_userLogoContainer__LYKXC {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #245995;
  display: flex;
  justify-content: center;
  align-items: center;

}
.Logo_userLogoContainerMobile__255Qw {
  height: 30px;
  width: 30px; 
}
.Logo_userLogoContainer__LYKXC > span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .26px;
  color: #245995;
  text-transform: uppercase;
}
.Logo_userLogoContainer__LYKXC:hover {
  cursor: pointer;
}
.Header_headerContainer__2ywJS {
  height: 125px;
  background: #fff;
  border-bottom: 2px solid #132e53;
  width: 100%;
  box-shadow: 0 9px 9px rgba(0, 0, 0, 0.3);

}
.Header_headerContainer__2ywJS.Header_isOpen__1Ks3L {
  position: relative;
}
.Header_headerContainer__2ywJS.Header_isOpen__1Ks3L:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(241, 126, 33, 0.65);
}
.Header_hideMobile__edXe4 {
  display: none;
}
.Header_headerMobile__3NFhG {
  height: 100px;
}
.Header_headerMobile__3NFhG .Header_contentContainer__2-MjK {
  padding: 0 30px !important;
}
.Header_headerMobile__3NFhG .Header_rightSide__11QMs button {
  margin-left: 22px;
}
.Header_contentContainer__2-MjK {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
}
.Header_headerMobile__3NFhG .Header_contentContainer__2-MjK {
  height: 70px;
}
.Header_leftSide__2LBZL {
  display: flex;
  align-items: center;
}
.Header_leftSide__2LBZL svg {
  height: 27px;
}
.Header_rightSide__11QMs {
  flex: 1 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

img {
  max-width: 100%; }

.headerTop {
  background: #f58533; }
  .headerTop .container {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 400px) {
      .headerTop .container {
        padding-right: 0 !important; } }
  .headerTop a {
    color: #FFF;
    text-decoration: none; }
  .headerTop .left {
    font-family: 'AntennaCond';
    color: #FFF;
    display: flex; }
    .headerTop .left > * {
      display: flex;
      margin-right: 12px;
      align-items: center; }
      @media only screen and (max-width: 400px) {
        .headerTop .left > * {
          font-size: 12px; } }
      .headerTop .left > *:last-child {
        margin-right: 0; }
      .headerTop .left > * > img {
        margin-right: 6px; }
        @media only screen and (max-width: 400px) {
          .headerTop .left > * > img {
            max-width: 14px; } }
    .headerTop .left a {
      text-transform: lowercase; }
  .headerTop .right {
    margin: 0 0 0 auto;
    display: flex;
    list-style: none; }
    .headerTop .right li {
      border-left: 1px solid #FFF; }
      .headerTop .right li a {
        display: block;
        padding: 12px 10px; }
        @media only screen and (max-width: 400px) {
          .headerTop .right li a {
            padding: 8px 10px; } }

.headerMain {
  border-bottom: 2px solid #132e53; }
  .headerMain .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .headerMain .container .logo {
        max-width: 180px; } }
    .headerMain .container .rightNav {
      margin-left: auto;
      display: flex;
      list-style: none;
      -webkit-transition: all .4s ease-in;
      transition: all .4s ease-in; }
      @media only screen and (max-width: 767px) {
        .headerMain .container .rightNav {
          display: none; } }
      .headerMain .container .rightNav li {
        margin-right: 20px; }
        @media only screen and (max-width: 991px) {
          .headerMain .container .rightNav li {
            margin-right: 10px; } }
        .headerMain .container .rightNav li:last-child {
          margin-right: 0; }
        .headerMain .container .rightNav li a {
          display: block;
          font-size: 14px;
          color: #29649f;
          padding: 32px 12px;
          text-decoration: none;
          font-weight: 700;
          -webkit-transition: all .4s ease;
          transition: all .4s ease; }
          @media only screen and (max-width: 991px) {
            .headerMain .container .rightNav li a {
              font-size: 13px;
              padding: 32px 8px; } }
          .headerMain .container .rightNav li a.active, .headerMain .container .rightNav li a:hover {
            background: #132e53;
            color: #f58533; }
      .headerMain .container .rightNav.hamburger {
        display: none;
        margin-right: 0;
        height: auto;
        overflow: unset; }
        @media only screen and (max-width: 767px) {
          .headerMain .container .rightNav.hamburger {
            display: flex;
            flex: 1 1;
            justify-content: flex-end; } }
        .headerMain .container .rightNav.hamburger li {
          display: inline-flex; }
          .headerMain .container .rightNav.hamburger li a {
            background: transparent;
            max-width: 40px;
            padding: 20px 8px;
            display: block; }
      .headerMain .container .rightNav.open {
        overflow: unset;
        display: block;
        position: absolute;
        z-index: 9;
        top: 65px;
        left: 0;
        width: calc( 100% + 15px);
        background: #FFF; }
        .headerMain .container .rightNav.open li {
          border-bottom: 1px solid rgba(19, 46, 83, 0.2); }
          .headerMain .container .rightNav.open li a {
            text-align: center;
            padding: 18px 8px; }

.Button_button__3km4W {
  height: 46px;
  width: 100%;

  padding: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  
  border: 1px solid transparent;
  border-radius: 10px;
  text-decoration: none;
  text-transform: uppercase;

  -webkit-transition: .3s;

  transition: .3s;
}
.Button_button__3km4W:hover {
  cursor: pointer;
}
.Button_button__3km4W:focus {
  outline: none;
}
.Button_button__3km4W p:focus {
  outline: none;
  color: #f19b2e;
}
.Button_button__3km4W>svg {
  margin: 0 10px 0 0 !important;
}

.Button_primary__SheVc {
  color: #ffffff;
  background-color: #245995;
}
.Button_primary__SheVc:hover {
  background-color: #f19b2e;
}
.Button_primary__SheVc:active {
  background-color: #1D3796;
}
.Button_primary__SheVc:disabled {
  color: #b4c6dc;
  background-color: #dfe7f2;
}

.Button_secondary___ZN7s {
  border: 1px solid #b7b3ce;
  color: #454452;
}
.Button_secondary___ZN7s:hover {
  background-color: #FACCA7;
}
.Button_secondary___ZN7s:focus {
  color: #454452;
}
.Button_secondary___ZN7s:active {
  background: #FACCA7;
}
.Button_secondary___ZN7s:disabled {
  color: #b4c6dc;
  background: #dfe7f2;
  border: 1px solid transparent;
}

.Button_link__1XiGb {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
}

.Button_confirmation__3fA-R {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1D3796;
  border: 3px solid #245995;
  border-radius: 90px;
}
.Button_confirmation__3fA-R>svg {
  margin: 0 !important;
}
.Dropdown_dropdownContainer__1wO4j {
  position: relative;
}
.Dropdown_value__1rBm0 {
  white-space: nowrap;
}
.Dropdown_value__1rBm0:hover {
  cursor: pointer;
}
.Dropdown_menu__3jUFr {
  position: absolute;
  top: 0;
  right: 0;
}
.Dropdown_menuContainer__3HMb0 {
  position: relative;
  padding: 40px 40px 35px; 
  height: 100%;
}
.Dropdown_menuContainer__3HMb0 > svg {
  position: absolute;
  top: -8px;
  left: 0;
}

.HeaderStyles_baseHeaderMobileMenu__2WS1G,
.HeaderStyles_baseHeaderMobileSearch__2_MmN {
  position: fixed;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  margin: 100px 0 0;
  padding: 77px 35px 75px;

  list-style: none;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  overflow-y: scroll;
}
.HeaderStyles_visibleInfo__1Dnrj,
.HeaderStyles_visibleMenu__2cfiu {
  top: 0;
}
.HeaderStyles_baseHeaderMobileMenu__2WS1G > li {
  margin-bottom: 55px;
}
.HeaderStyles_baseHeaderMobileMenu__2WS1G > li > a,
.HeaderStyles_baseHeaderMobileMenu__2WS1G > li > div {
  display: block;
  text-decoration: none;
  font-size: 20px;
  line-height: 26px;
  color: #454452;
}
.HeaderStyles_baseHeaderMobileMenu__2WS1G > li > a:hover,
.HeaderStyles_baseHeaderMobileMenu__2WS1G > li > div:hover,
.HeaderStyles_baseHeaderMobileMenu__2WS1G > li > a:focus {
  color: #245995;
}
.HeaderStyles_baseHeaderMobileMenu__2WS1G>.HeaderStyles_close__oyM3y {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px;
  margin: 0;
}
.HeaderStyles_baseHeaderMobileMenu__2WS1G .HeaderStyles_separator__3k0I5 {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 52px;
}
.HeaderStyles_baseHeaderMobileSearch__2_MmN .HeaderStyles_baseHeaderMobileSearchContainer__ZJcZa {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px 0;
}
.HeaderStyles_baseHeaderMobileSearchContainer__ZJcZa > .HeaderStyles_close__oyM3y {
  padding: 12px;

  position: absolute;
  right: 0;
  top: 0;
}
.HeaderStyles_baseHeaderMobileSearchContainer__ZJcZa > .HeaderStyles_searchContainer__rMYU2 > input {
  width: 100%;
  padding: 10px;

  border: 1px solid #eee;
  border-radius: 15px;
  
  font-size: 14px;
}
.HeaderStyles_Button__113_d {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeaderStyles_ButtonLink__LNhNI {
  font-weight: 400;
  text-decoration: underline;
}
.HeaderStyles_separator__3k0I5 {
  color: #245995;
  margin-left: 27px;
}
.HeaderStyles_Button__113_d {
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
  color: #29649f;
  padding: 32px 12px;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 700;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.HeaderStyles_selected__PoucP,
.HeaderStyles_Button__113_d:hover {
  background: #132e53;
  color: #f58533;
}
.HeaderStyles_Button__113_d:focus {
  color: #f19b2e;
}

.HeaderStyles_userMenu__170bl {
  display: flex;
  flex-direction: column;

  top: 50px;
  right: -10px;
  z-index: 1;
  
  width: 275px;
  
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(103,105,118, 0.19);
}
.HeaderStyles_userMenu__170bl .HeaderStyles_separator__3k0I5 {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 37px;
}
.HeaderStyles_userMenu__170bl a {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #454452;
  text-decoration: none;
  margin-bottom: 31px;
}
.HeaderStyles_userMenu__170bl a:last-child {
  margin-bottom: 0;
}
.HeaderStyles_userMenu__170bl a:hover,
.HeaderStyles_userMenu__170bl a:focus {
  color: #245995;
}
.HeaderStyles_userMenu__170bl>.HeaderStyles_menuContainer__1bIg7 {
  height: 100px;
}
.HeaderStyles_logoContainer__120du {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-left: 16px;
}
.HeaderStyles_photo__2F5YM {
  height: 35px;
  width: 35px;

  border: 2px solid #0459FF;
  border-radius: 50%;

  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 991px) {	
    .HeaderStyles_ButtonLink__LNhNI,
    .HeaderStyles_Button__113_d {
      max-width: 136px;
      float: right;
      margin-left: 10px;
      line-height: 17px;
      letter-spacing: .40px;
      font-size: 14px;
      font-weight: 400;
      color: #245995;
    }
}

.mainFooter {
  margin-top: 20px;
  background: #132e53 url(/static/media/footer_bg.bf5fbe03.jpg) no-repeat;
  padding-top: 53px; }
  .mainFooter .footerLogo {
    background: #ffffff;
    display: flex;
    width: 280px;
    height: 65px;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin: 0 auto 40px; }
  .mainFooter .footerNav {
    display: flex;
    justify-content: center;
    list-style: none;
    font-weight: 700;
    padding-bottom: 32px;
    border-bottom: 1px solid #224b83;
    margin: 0 auto 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    @media only screen and (max-width: 640px) {
      .mainFooter .footerNav {
        padding-bottom: 10px;
        flex-wrap: wrap; } }
    .mainFooter .footerNav a {
      margin-right: 40px; }
      @media only screen and (max-width: 640px) {
        .mainFooter .footerNav a {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 12px;
          margin-bottom: 10px; } }
      .mainFooter .footerNav a:last-child {
        margin-right: 0; }
    .mainFooter .footerNav a {
      color: #29649f;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px; }
      .mainFooter .footerNav a:hover, .mainFooter .footerNav a.active {
        border-color: #f5b151;
        color: #f5b151; }
  .mainFooter .footerContact {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #224b83;
    padding-bottom: 20px;
    list-style: none;
    text-transform: none; }
    .mainFooter .footerContact li {
      margin-right: 12px; }
    .mainFooter .footerContact a {
      align-items: center;
      display: flex;
      color: #ffffff;
      text-transform: none; }
      .mainFooter .footerContact a img {
        margin-right: 7px; }
  .mainFooter .footerBottom {
    font-size: 14px; }
    .mainFooter .footerBottom .container {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      color: #ffffff; }
      @media only screen and (max-width: 640px) {
        .mainFooter .footerBottom .container {
          display: block; } }
      .mainFooter .footerBottom .container a {
        color: #ffffff; }
      .mainFooter .footerBottom .container .copyRight {
        flex: 1 1;
        justify-content: center;
        text-align: center;
        padding: 0 15px; }
        @media only screen and (max-width: 640px) {
          .mainFooter .footerBottom .container .copyRight {
            padding-bottom: 10px; } }
      .mainFooter .footerBottom .container .footerSocial {
        list-style: none;
        display: flex;
        flex: 1 1; }
        @media only screen and (max-width: 640px) {
          .mainFooter .footerBottom .container .footerSocial {
            justify-content: center;
            padding-bottom: 10px; } }
        .mainFooter .footerBottom .container .footerSocial li {
          border-right: 1px solid #ffffff; }
          .mainFooter .footerBottom .container .footerSocial li a {
            padding: 0 10px; }
          .mainFooter .footerBottom .container .footerSocial li:last-child {
            border: none; }
      .mainFooter .footerBottom .container .terms {
        flex: 1 1;
        text-align: right; }
        @media only screen and (max-width: 640px) {
          .mainFooter .footerBottom .container .terms {
            text-align: center; } }


.TextBox_input__1B9UR{
  width: 100%;
}
.TextBox_input__1B9UR label {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.TextBox_error__2Fh6s {
  position: absolute;
  top: 65px;
  margin-top: 0 !important;
}
.TextBox_errorTextArea__1umNH {
  position: absolute;
  bottom: -20px;
  margin-top: 0 !important;
}
.TextBox_input__1B9UR label[data-shrink="true"] {
  font-size: 14px;
  -webkit-transform: translate(0, 1.5px) scale(1);
          transform: translate(0, 1.5px) scale(1);
}
.TextBox_input__1B9UR div[type="text"] {
  display: flex;
  padding: 0;
  height: 43px;
}
.TextBox_input__1B9UR div[type="text"] > div {
  padding: 0;
}

.TextBox_input__1B9UR>div:before,
.TextBox_input__1B9UR>div:hover:before {
  border-bottom: 1px solid #b7b3ce !important;
}
.TextBox_input__1B9UR>div:after {
  border-bottom: 1px solid #245995;
}
.TextBox_input__1B9UR input {
  padding: 14px 0 10px;
  color: #454452;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.04em;
}
.TextBox_input__1B9UR input:disabled {
  color: #b1afba;
}
.TextBox_input__1B9UR textarea {
  min-height: 30px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.04em;
}
.TextBox_visibilityIcon__1vpul {
  color: #245995;
}
.TextBox_password__7YX6X {
  position: relative;
}
.TextBox_toggleVisibility__2ksnX {
  position: absolute;
  right: 0;
  top: 29px;
  
  padding: 0;
  
  width: 25px;
  height: 20px;
}
.TextBox_toggleVisibility__2ksnX>svg {
  width: 25px;
  height: 20px;
}
.TextBox_toggleVisibility__2ksnX:hover {
  cursor: pointer;
}
.TextBox_password__7YX6X button {
  background-color: transparent;
}
.TextBox_password__7YX6X input {
  padding-right: 25px;
}
.RadioButton_radioButtonContainer__2brEu {
  position: relative;
  margin-bottom: 0 !important;
}
.RadioButton_radioButtonContainer__2brEu .RadioButton_label__2qWAr {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  
  color: #858297;
}
.RadioButton_radioButtonContainer__2brEu .RadioButton_error__3vvJ0 {
  position: absolute;
  left: 0;
  bottom: -15px;

  color: #FF3C3C;
  font-size: 0.75rem;
  text-align: left;
  min-height: 1em;
  line-height: 1em;
  letter-spacing: normal;
  letter-spacing: initial;
}
.RadioButton_radioButtonContainer__2brEu .RadioButton_radio__By3pt {
  margin-right: 20px;
}
.RadioButton_radioButtonContainer__2brEu .RadioButton_radio__By3pt span:first-child {
  padding: 8px;
}
.RadioButton_radioButtonContainer__2brEu .RadioButton_radio__By3pt span:last-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #454452;
}
.RadioButton_disabled__1OYhU {
  opacity: .5;
}

.UserContainer_userContainer__12wT0 {
  display: flex;
  margin: 40px auto;
  width: 100%;
  padding: 0 15px;
  flex: 1 1;
  max-width: 1440px;
  overflow-y: hidden;
}
.UserContainer_pageContainer__3g9-0 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  margin-left: 0 !important;
}
.UserContainer_userContainerMobile__25tzo {
  flex-direction: column;
}
.UserContainer_navigation__16YUS {
  width: 200px;
  border-right: 1px solid #e4e4e4;
}
.UserContainer_userContainerMobile__25tzo .UserContainer_navigation__16YUS {
  width: 100%;
  margin: 0;
  border-right: 0;
  border-bottom: 1px solid #e4e4e4;
}
.UserContainer_navigation__16YUS a:focus {
  color: #245995;
}

.UserContainer_nav__1_QN0 {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}
.UserContainer_nav__1_QN0>.UserContainer_link__3eG7C {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  margin-right: 15px;
}
.UserContainer_nav__1_QN0>.UserContainer_selected__1Yaur {
  color: #245995;
  text-decoration: underline;
}

.UserContainer_content__LTmFX {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-left: 40px;
}
.UserContainer_userContainerMobile__25tzo .UserContainer_content__LTmFX {
  margin: 37px 0 40px;
}
.UserContainer_link__3eG7C {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  display: flex;
  margin-bottom: 20px;
}
.UserContainer_link__3eG7C > svg {
  margin-right: 12px;
}
.UserContainer_link__3eG7C:hover {
  color: #245995;
}
.UserContainer_link__3eG7C:hover>svg path {
  fill: #245995;
}
.UserContainer_selected__1Yaur {
  color: #245995;
}
.UserContainer_selected__1Yaur>svg path {
  fill: #245995;
}
.UserContainer_contentTitle__3V_6J {
  margin-bottom: 65px;
}
.UserContainer_contentTitleMobile__3sSI8 {
  margin-bottom: 39px;
}
.UserContainer_title__1gejk {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.UserContainer_balance__3CNuI {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #245995;
}
.UserContainer_formContainer__1rmJL h1.UserContainer_phone__3kPwp {
  margin: 30px 0 30px -30px;
}
.UserContainer_tip__K3xEi {
  margin: -20px 0 20px;
}
.UserContainer_subTitle__2voB- {
  margin: 0 30px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #858297;
}
.UserContainer_info__3NAtO {
  margin: 0 30px 30px;
  font-weight: 500;
  line-height: 26px;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;
}
.UserContainer_talk__2KSv4 {
  margin: 0;
  font-weight: normal;
  line-height: 26px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #454452;
}
.UserContainer_talk__2KSv4 a {
  color: #245995;
  font-size: 14px;
  font-weight: 400;
}
.UserContainer_settingsPageContainer__TX7o- {
  flex: 1 1;
  display: flex;
  width: 100%;
}
.UserContainer_blockContainer__DbpbH {
  border: 1px solid #b7b3ce;
  margin-left: 30px;
  padding: 28px 40px 26px;
  flex: 1 1;
  /* width: 440px; */
}
.UserContainer_blockContainerMobile__2XzLF {
  width: 300px;
  padding: 28px 20px 26px;
} 
.UserContainer_forgot__3dhD_ {
  background: #FFE0C7;
  border: 1px solid #245995;
  padding: 15px 27px;
}
.UserContainer_blockContainer__DbpbH a {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;

  display: flex;
  align-items: center;
}
.UserContainer_blockContainer__DbpbH h3 {
  margin: 0 0 4px;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #245995;
  height: auto;
  text-align: left;
}
.UserContainer_blockContainer__DbpbH p {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #5C5B66;
}

.UserContainer_blockContainer__DbpbH a > svg {
  margin-left: 10px;
}
.UserContainer_form__1U8Rf > div {
  position: relative;
  margin: 0 0 35px;
}
.UserContainer_userContainer__12wT0 .UserContainer_form__1U8Rf {
  margin-left: 30px;
} 
.UserContainer_userContainerMobile__25tzo .UserContainer_form__1U8Rf {
  margin-right: 30px;
}
.UserContainer_Button__1lmp0 {
  max-width: 136px;
  float: right;
  margin-top: 36px;
}
.UserContainer_userContainerMobile__25tzo .UserContainer_Button__1lmp0 {
  margin: 36px auto 0;
  float: none;
}
.UserContainer_ButtonCancel__1n8Kp {
  background-color: #b1afba;
}

.UserContainer_DropdownContainer__3955l {
  width: 100%;
}
.UserContainer_DropdownContainer__3955l>div:nth-child(3)>div>div:last-child {
  border-bottom: 0 !important;
}
/*custom scroll*/
.UserContainer_DropdownContainer__3955l>div:nth-child(3)::-webkit-scrollbar {
  width: 8px;
}
.UserContainer_DropdownContainer__3955l>div:nth-child(3)::-webkit-scrollbar-track {
  background: transparent;
}
.UserContainer_DropdownContainer__3955l>div:nth-child(3)::-webkit-scrollbar>* {
  background:transparent;
}
.UserContainer_DropdownContainer__3955l>div:nth-child(3)::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.UserContainer_DropdownContainer__3955l>div:nth-child(3)::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.UserContainer_DropdownContainer__3955l>div:nth-child(3)::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}
.UserContainer_error__1YlMH {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
.UserContainer_confirmationContainer__1pjNm {
  margin: 36px 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.UserContainer_success__1wQ7p {
  flex: 1 1;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: .04em;
  color: #245995;
}

.UserContainer_containerX__Lj-2Y {
  display: flex;
  align-items: stretch;
  margin: 40px auto;
  width: 100%;
  flex: 1 1;
  max-width: 960px !important;
}
.UserContainer_noMargin__1prZT {
  margin: 0 auto;
  padding: 0 !important;
}

.UserContainer_textBoxContainer__WK8FL {
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding-top: 20px;
  background: #fff;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS {
  max-height: 126px;

  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;

  border: 1px solid #E5E4EE;
  border-radius: 6px;
  padding: 14px;
  flex: 1 1;
  
  word-break: break-all;
  resize: none;
  overflow: auto;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS:hover {
  border: 1px solid #B7B3CE;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS:focus {
  border: 1px solid #454452;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS::-webkit-input-placeholder {
  color: #B1AFBA;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS::-moz-placeholder {
  color: #B1AFBA;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS::-ms-input-placeholder {
  color: #B1AFBA;
}
.UserContainer_textBoxContainer__WK8FL .UserContainer_textInput__sjsSS::placeholder {
  color: #B1AFBA;
}
.UserContainer_textInput__sjsSS::-webkit-scrollbar {
  width: 4px;
}
.UserContainer_textInput__sjsSS::-webkit-scrollbar-track {
  background: transparent;
}
.UserContainer_textInput__sjsSS::-webkit-scrollbar>* {
  background:transparent;
}
.UserContainer_textInput__sjsSS::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.UserContainer_textInput__sjsSS::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.UserContainer_textInput__sjsSS::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.UserContainer_textSubmitButton__wj9Ns {
  margin-left: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #245995;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UserContainer_textSubmitButton__wj9Ns:disabled {
  background: #DFE7F2;
}
.UserContainer_textSubmitButton__wj9Ns:disabled svg path {
  fill: #B4C6DC;
}
.UserContainer_textSubmitButton__wj9Ns > svg {
  margin-left: -3px;
}

.UserContainer_noMessages__21yCx {
  margin: auto;
  width: 100%;
  max-width: 390px;
  height: 248px;
  padding: 46px 64px 51px;
  border: 1px solid #E5E4EE;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1 1;
}
.UserContainer_noMessages__21yCx h1 {
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #5C5B66;
}
.UserContainer_noMessages__21yCx>p {
  margin: 8px 0 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #5C5B66;
}
.UserContainer_noMessages__21yCx .UserContainer_ButtonLink__25hw9 {
  margin: 0 auto;
  max-width: 176px;
}

.UserContainer_registrationContainer__3Zq0j {
  max-width: 528px !important;
  margin-bottom: 40px;
  -webkit-overflow-scrolling: touch;
}
.UserContainer_registrationContainerMobile__2cUqF {
  max-width: 275px !important;
}
.UserContainer_registrationContainer__3Zq0j .UserContainer_logo__2zyRW {
  text-align: center;
}
.UserContainer_container__1kZwU {
  display: flex;
}
.UserContainer_container__1kZwU>div {
  flex: 1 1;
}
.UserContainer_dashboardContainerMobile__22ZHt .UserContainer_container__1kZwU {
  flex-direction: column;
}
.UserContainer_container__1kZwU h6 {
  color: #245995;
  margin: 0 0 12px;
}
.UserContainer_dashboardContainerMobile__22ZHt .UserContainer_container__1kZwU>div>div {
  width: 100% !important;
}
.UserContainer_managerContainerMobile__1vBbF {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 10px;
}
.UserContainer_wrapper__2YkJz {
  /* overflow-y: hidden; */
  overflow-x: visible;
  padding-bottom: 10px;
  margin-bottom: 35px;
}
.UserContainer_billContainer__1FQQ2 {
  max-width: 670px;
}
.UserContainer_billContainer__1FQQ2 .UserContainer_subTitle__2voB- {
  margin-left: 0;
}
.UserContainer_rateContainerMobile__rywRU .UserContainer_table__1CoAM,
.UserContainer_managerContainerMobile__1vBbF .UserContainer_table__1CoAM {
  min-width: 650px;
}
.UserContainer_dashboardContainer__1B_To,
.UserContainer_managerContainer__1JbUK {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.UserContainer_dashboardContainerMobile__22ZHt,
.UserContainer_managerContainerMobile__1vBbF {
  min-height: 200px;
}
.UserContainer_managerContainer__1JbUK .UserContainer_containerTitle__1kNQ5 {
  padding-bottom: 20px;
  position: relative;
}
.UserContainer_managerContainer__1JbUK .UserContainer_modal__11V5j section {
  min-height: 450px;
}
.UserContainer_managerContainer__1JbUK .UserContainer_modal__11V5j section>div {
  display: flex;
  flex-direction: column;
}
.UserContainer_status__s8p7Y {
  position: absolute;
  bottom: 0;
  color: #245995;
}
.UserContainer_table__1CoAM {
  display: flex;
  flex-direction: column;
}  
.UserContainer_table__1CoAM .UserContainer_header__2QsXD,
.UserContainer_table__1CoAM .UserContainer_row__25swa {
  display: flex;
}
.UserContainer_row__25swa {
  font-size: 14px;
}
.UserContainer_table__1CoAM .UserContainer_header__2QsXD div,
.UserContainer_table__1CoAM .UserContainer_row__25swa>div {
  flex: 1 1;
  padding: 5px 10px;
  border: 1px solid #e4e4e4;
  min-width: 60px;
}
.UserContainer_table__1CoAM .UserContainer_header__2QsXD div:first-child,
.UserContainer_table__1CoAM .UserContainer_row__25swa>div:first-child {
  min-width: 200px;
}
.UserContainer_userContainerMobile__25tzo .UserContainer_table__1CoAM .UserContainer_row__25swa>div {
  min-width: 120px;
}
.UserContainer_billContainer__1FQQ2 .UserContainer_table__1CoAM .UserContainer_header__2QsXD div,
.UserContainer_billContainer__1FQQ2 .UserContainer_table__1CoAM .UserContainer_row__25swa>div {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}
.UserContainer_userContainerMobile__25tzo .UserContainer_table__1CoAM .UserContainer_header__2QsXD div {
  min-width: 120px;
}
.UserContainer_table__1CoAM .UserContainer_header__2QsXD .UserContainer_remove__3e08q,
.UserContainer_table__1CoAM .UserContainer_row__25swa .UserContainer_remove__3e08q {
  background-color: white;
  text-align: right;
  font-weight: 700;
}
.UserContainer_remove__3e08q button {
  font-size: 14px;
  margin: 0 auto;
  display: block;
  color: #245995;
}
.UserContainer_remove__3e08q button:hover {
  cursor: pointer;
}
.UserContainer_row__25swa.UserContainer_active__3vE4a {
  color: #b1afba;
}
.UserContainer_table__1CoAM .UserContainer_header__2QsXD div { 
  background-color: rgb(238, 238, 238);
  color: #245995;
}
.UserContainer_billContainer__1FQQ2 .UserContainer_table__1CoAM .UserContainer_header__2QsXD > div {
  background-color: rgb(238, 238, 238);
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.UserContainer_table__1CoAM .UserContainer_header__2QsXD > div svg {
  min-width: 20px;
}

/*Mobile scrooling*/
.UserContainer_managerContainerMobile__1vBbF::-webkit-scrollbar {
  width: 2px;
}
.UserContainer_managerContainerMobile__1vBbF::-webkit-scrollbar-track {
  background: transparent;
}
.UserContainer_managerContainerMobile__1vBbF::-webkit-scrollbar>* {
  background:transparent;
}
.UserContainer_managerContainerMobile__1vBbF::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.UserContainer_managerContainerMobile__1vBbF::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.UserContainer_managerContainerMobile__1vBbF::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.UserContainer_modal__11V5j section {
  width: 600px;
  max-height: 500px;
}
.UserContainer_registrationContainerMobile__2cUqF .UserContainer_modal__11V5j section {
  width: 300px;
  max-height: 500px;
}
.UserContainer_modal__11V5j form {
  flex: 1 1;
  width: 100%;
  max-width: 350px;
  margin: 20px auto;
}
.UserContainer_managerContainer__1JbUK .UserContainer_modal__11V5j form>div {
  margin-bottom: 35px;
}
.UserContainer_registrationContainer__3Zq0j .UserContainer_Button__1lmp0,
.UserContainer_managerContainer__1JbUK .UserContainer_Button__1lmp0 {
  margin-top: 20px;
  float: none;
  float: initial;
}
.UserContainer_registrationContainer__3Zq0j .UserContainer_modal__11V5j .UserContainer_error__1YlMH {
  margin: 0;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
}
.UserContainer_managerContainer__1JbUK .UserContainer_error__1YlMH {
  margin: 0;
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
}
.UserContainer_managerContainer__1JbUK .UserContainer_modal__11V5j form>div:last-child {
  margin-bottom: 20px;
}
.UserContainer_modal__11V5j section h3 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.UserContainer_managerContainerMobile__1vBbF .UserContainer_modal__11V5j section {
  width: 300px;
  max-height: 500px;
  padding: 23px 18px 26px;
}
.UserContainer_techFormContainer__7MqYh .UserContainer_table__1CoAM .UserContainer_row2__10B8g>div:first-child {
  flex: 0 0 33.3333%;
}
.UserContainer_datePicker__3JwKw>div>div {
  width: 100% !important;
}
.UserContainer_datePicker__3JwKw>div>div>span {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.UserContainer_datePicker__3JwKw>div>div>div>div {
  width: 100%;
}
.UserContainer_datePicker__3JwKw>div>div+span {
  position: absolute;
  color: #f44336 !important;
  font-size: 0.75rem !important;
  margin-top: 8px !important;
  line-height: 1em;
}
.UserContainer_datePicker__3JwKw>div>div:before {
  border-bottom: 1px solid #b7b3ce !important;
}
.UserContainer_datePicker__3JwKw>div>div input {
  width: 100% !important;
  padding: 14px 0 10px !important;
  color: #454452 !important;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px !important;
  letter-spacing: 0.04em !important;
}
.UserContainer_popper__3mSAU {
  min-width: 200px !important;
}
.UserContainer_modal__11V5j .UserContainer_containerBtn__1OPm6 {
  display: flex;
  margin: 40px 0 0;
  justify-content: space-between;
}
.UserContainer_modal__11V5j .UserContainer_containerBtn__1OPm6 button {
  margin: 0;
  max-width: 100px;
}
.UserContainer_modal__11V5j .UserContainer_modalTitle__M6YFq {
  margin: 0;
  font-size: 14px;
}
.UserContainer_modal__11V5j .UserContainer_modalText__3mWnn {
  margin: 40px 0 20px;
  font-size: 14px;
  line-height: 23.4px;
  color: #b1afba;
  position: relative;
}
.UserContainer_modal__11V5j .UserContainer_modalPhoneNumber__3Z8lQ {
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #454452;
}
.UserContainer_containerTitle__1kNQ5 {
  display: flex;
  margin-bottom: 22px;
}
.UserContainer_techFormContainer__7MqYh {
  margin: 40px 0;
  border-top: 1px solid #454452;
  padding-top: 20px;
}

.UserContainer_dashboardContainerMobile__22ZHt .UserContainer_containerTitle__1kNQ5 {
  flex-direction: column;
}
.UserContainer_dashboardContainerMobile__22ZHt .UserContainer_container__1kZwU>div {
  margin-bottom: 20px;
}
.UserContainer_containerTitle__1kNQ5 .UserContainer_title__1gejk {
  flex: 1 1;
}
.UserContainer_addButton__21s8O {
  max-width: 100px;
  text-transform: none;
  text-transform: initial;
}
.UserContainer_rateContainer__2GyA5 .UserContainer_container__1kZwU {
  justify-content: space-between;
}
.UserContainer_rateContainer__2GyA5 .UserContainer_container__1kZwU a {
  line-height: 20px;
}
.UserContainer_rateContainerMobile__rywRU .UserContainer_container__1kZwU {
  flex-direction: column;
}
.UserContainer_staticRate__siUWU {
  margin: 30px 0;
}
.UserContainer_staticRate__siUWU h1,
.UserContainer_staticRate__siUWU .UserContainer_container__1kZwU {
  margin: 30px;
}
.UserContainer_rateContainerMobile__rywRU.UserContainer_staticRate__siUWU h1,
.UserContainer_rateContainerMobile__rywRU.UserContainer_staticRate__siUWU .UserContainer_container__1kZwU {
  margin-left: 0px;
}
.UserContainer_rateContainerMobile__rywRU.UserContainer_staticRate__siUWU {
  margin: 30px;
}
.UserContainer_staticRate__siUWU .UserContainer_table__1CoAM {
  margin: 0 30px;
}
.UserContainer_rateContainerMobile__rywRU.UserContainer_staticRate__siUWU .UserContainer_table__1CoAM {
  margin: 0;
}
.UserContainer_payContainer__2J1-2 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.UserContainer_payContainer__2J1-2 form>div {
  margin-bottom: 20px;
}
.UserContainer_payContainer__2J1-2 .UserContainer_infoContainer__3V4Bc {
  position: relative;
  height: 17px;
  margin: 0;
}
.UserContainer_payContainer__2J1-2 .UserContainer_infoContainer__3V4Bc span {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
}
.UserContainer_error__1YlMH {
  color: #f44336;
}
.UserContainer_loading__3blgu {
  color: #b1afba;
}
.UserContainer_updated__3Y0Sz {
  color: #245995;
}
.UserContainer_loader__3Oj07 {
  position: absolute;
}
.UserContainer_body__3sF_6 {
  min-height: 20px;
}
.UserContainer_payContainer__2J1-2 form>.UserContainer_checkbox__pg0Z8 {
  margin-left: -12px;
  margin-bottom: 0;
}
.UserContainer_checkbox__pg0Z8 > div {
  display: inline-flex;
  align-items: center;
}
.UserContainer_label__1H7aT {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.UserContainer_payContainer__2J1-2 .UserContainer_Button__1lmp0 {
  margin-top: 19px;
  float: left;
}
.UserContainer_payContainer__2J1-2 .UserContainer_Button__1lmp0:last-child {
  margin-top: 19px;
  float: right;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_paymentForm__1epwI {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_paymentForm__1epwI .UserContainer_Button__1lmp0 {
  float: none;
  margin: 0 0 0 20px;
}


.UserContainer_invoice__3jSzt {
  line-height: 24px;
}
.UserContainer_invoice__3jSzt header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #b1afba;
}
.UserContainer_paymentContainerMobile__DDsPa .UserContainer_invoice__3jSzt header>div:first-child {
  margin-bottom: 20px;
}
.UserContainer_paymentContainerMobile__DDsPa .UserContainer_invoice__3jSzt header {
  flex-direction: column;
}
.UserContainer_invoice__3jSzt h1 {
  text-align: center;
  color: #454452;
  font-size: 24px;
}
.UserContainer_invoice__3jSzt .UserContainer_to__MoBTf {
  margin: 20px 0 40px;
  display: flex;
  justify-content: space-between;
}
.UserContainer_to__MoBTf .UserContainer_company__2bSTh {
  margin-left: 25px;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:first-child {
  font-weight: bold;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(2)>div:first-child {
  border-right: 0 !important;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(2)>div:nth-child(2) {
  border-left: 0 !important;
  border-right: 0 !important;
  text-align: right;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(2)>div:nth-child(3) {
  border-left: 0 !important;
  text-align: right;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(3)>div:nth-child(2) {
  border-right: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(3)>div:nth-child(2)>div:last-child {
  border-top: 1px solid #e4e4e4;
  margin: 0 -10px;
  padding-left: 10px;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(3)>div:nth-child(3) {
  border-left: 0 !important;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa:nth-child(3)>div:nth-child(3)>div:last-child {
  border-top: 1px solid #e4e4e4;
  margin: 0 -10px;
  padding-right: 10px;
}
.UserContainer_paymentContainer__T6l5V .UserContainer_table__1CoAM .UserContainer_row__25swa>div:first-child {
  flex: 0 0 60%;
}

.UserContainer_pagination__1acEp {
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}
.UserContainer_pagination__1acEp>*{
  list-style-type: none;
  line-height: 23.4px;
}
.UserContainer_pagination__1acEp>* a {
  text-decoration: none;
  font-size: 18px;
}
.UserContainer_page__2B2Ub,
.UserContainer_break__1mZ_T {
  margin-right: 10px;
}
.UserContainer_page__2B2Ub>a {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
} 
.UserContainer_active__3vE4a {
  text-decoration: underline;
}
.UserContainer_active__3vE4a>a {
  color: #245995;
  font-weight: bold;
}
.UserContainer_prev__1apiK {
  margin-right: 20px;
}
.UserContainer_next__9iNij {
  margin-left: 10px;
}

/* Search Form styles */
.UserContainer_searchForm__HKYF6 {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

.UserContainer_searchForm__HKYF6 > div {
  max-width: 250px;
  margin-right: 30px;
}

.UserContainer_searchButton__em5M9 {
  max-width: 100px;
  margin-right: 20px;
}
.Loading_wrapper__18MsU {
  position: relative;
  width: 100%;
  flex: 1 1;
}

.Loading_loader__1aFPC {
	height: 32px;
  width: 32px;
  display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}

.Loading_loader__1aFPC::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	background: #245995;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-1__3zZOQ 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-1__3zZOQ 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-1__3zZOQ {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
@keyframes Loading_loader-4-1__3zZOQ {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
.Loading_loader__1aFPC span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
  -webkit-animation: Loading_loader-4-2__2mJ_X 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-2__2mJ_X 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-2__2mJ_X {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes Loading_loader-4-2__2mJ_X {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.Loading_loader__1aFPC span::before,
.Loading_loader__1aFPC span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 12px;
	width: 12px;
	background: #245995;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-3__3gS6V 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-3__3gS6V 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-3__3gS6V {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-3__3gS6V {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
.Loading_loader__1aFPC span::after {
  -webkit-animation: Loading_loader-4-4__1KDhf 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-4__1KDhf 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-4__1KDhf {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-4__1KDhf {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}

.Modal_modal__3nFaL {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width:100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6);

  display: none;
  text-align: center;
}

.Modal_modalMain__2ufV4 {
  position:fixed;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);

  background: white;

  width: 100%;
  height: auto;
  width: 290px;
  max-height: 293px;

  padding: 46px 36px 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Modal_modalMain__2ufV4 h3 {
  margin: 0 -5px 11px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
}

.Modal_modalMain__2ufV4>div {
  width: 100%;
  flex: 1 1;
}
.Modal_modalMain__2ufV4 div>button {
  max-width: 136px;
  margin: 0 auto 17px;
}

.Modal_close__WEHWr {
  position: absolute;
  top: 0px;
  right: 0px;

  padding: 20px;
}
.Modal_close__WEHWr:hover {
  cursor: pointer;
}
.Modal_show__3SvD0 {
  display: block;
}

.aboutContainer {
  background: url(/static/media/about_bg.9736e270.jpg) no-repeat center;
  background-size: cover;
  min-height: 690px;
  display: flex;
  align-items: center;
  padding: 30px 0;
  margin-bottom: -20px; }
  .aboutContainer p {
    color: #626262;
    line-height: 24px; }
    .aboutContainer p a {
      color: #f58533;
      text-transform: none;
      text-transform: initial; }
  .aboutContainer .body p {
    margin-bottom: 20px; }
    .aboutContainer .body p:last-child {
      margin-bottom: 0; }

.heading-with-line {
  font-size: 30px;
  font-weight: 900;
  color: #20395c;
  padding-bottom: 25px;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1023px) {
    .heading-with-line {
      font-size: 24px; } }
  @media only screen and (max-width: 767px) {
    .heading-with-line {
      font-size: 18px; } }
  .heading-with-line:before {
    content: "";
    height: 26px;
    width: 7px;
    background: #f58533;
    display: block;
    margin-right: 20px; }
  .heading-with-line span {
    margin: 0 7px; }

.StaticStyles_staticContainer__1naJm {
  padding: 0 0 33px !important;
  max-width: 600px !important;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1.StaticStyles_staticTitle__2QC3H {
  margin: 36px 0 24px;
  font-size: 20px;
  font-weight: 500;
  color: #454452;
}
h3.StaticStyles_staticSubTitle__3IrJ8 {
  margin: 35px 0 19px;
  color: #245995;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .64px;
  text-align: left;
}
.StaticStyles_staticParagraph__tKXs3,
.StaticStyles_staticParagraphBold__1jScM {
  font-size: 14px;
  line-height: 26.2px;
  letter-spacing: .56px;
  color: #5c5b66;
  margin: 0;
}
.StaticStyles_staticParagraphBold__1jScM {
  font-weight: 500;
}

.StaticStyles_staticContainerMobile__pKm7X {
  max-width: 300px !important;
}
.StaticStyles_list__1izFB {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.StaticStyles_list__1izFB li {
  counter-increment: step-counter;
  position: relative;
  padding-left: 40px;
  padding-bottom: 40px;
}
.StaticStyles_list__1izFB li::before {
  content: counter(step-counter);
  font-size: 14px;
  color: #245995;

  border: 1px solid #245995;
  border-radius: 50%;

  position: absolute;
  top: 2px;
  left: 0px;

  width: 25px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.StaticStyles_list__1izFB li::after {
  border-left: 1px dashed#245995;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 13px;
  content: ' ';
}
.StaticStyles_list__1izFB li:last-child::after {
  display: none;
}
.StaticStyles_list__1izFB h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: .64px;
}
.StaticStyles_fund__30XW0 {
  margin: 50px 0;
}
.StaticStyles_fundMobile__3kh50 {
  margin: 28px 0 48px;
}
.StaticStyles_contactsContainer__3yoxt {
  width: 100%;
  max-width: 600px !important;
  padding: 0 !important;
  margin: 36px 0 41px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StaticStyles_contactsContainerMobile__1u08i {
  padding: 0 15px !important;
}
.StaticStyles_contactsContainer__3yoxt form {
  max-width: 540px;
}
.StaticStyles_contactsContainer__3yoxt h1 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  color: #454452;
}
.StaticStyles_contactsContainer__3yoxt .StaticStyles_subTitle__32WTq {
  margin: 0 0 35px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: .04em;
  color: #454452;
}
.StaticStyles_contactsContainer__3yoxt .StaticStyles_form__1GIwU {
  margin-left: 72px;
}
.StaticStyles_contactsContainerMobile__1u08i .StaticStyles_form__1GIwU {
  margin: 0 auto;
  max-width: 300px;
}
.StaticStyles_contactsContainer__3yoxt .StaticStyles_form__1GIwU > div {
  margin-bottom: 36px;
}
.StaticStyles_contactsContainer__3yoxt .StaticStyles_Button__3zjWF {
  max-width: 136px;
  margin: 0;
  float: right;
}
.StaticStyles_contactsContainerMobile__1u08i .StaticStyles_Button__3zjWF {
  max-width: 136px;
  margin: 0 auto;
}
.StaticStyles_error__29iiM {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
.StaticStyles_confirmationContainer__31WHu {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.StaticStyles_success__1DImD {
  flex: 1 1;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: .04em;
  color: #245995;
}

.StaticStyles_privacy__1iSNg .StaticStyles_date__wWdm9 {
  margin: 0 0 13px;
  font-weight: 500;
  line-height: 26px;
  font-size: 14px;

  color: #454452;
}
.StaticStyles_privacy__1iSNg .StaticStyles_listing__PHeI1 {
  display: flex;
}
.StaticStyles_privacy__1iSNg .StaticStyles_listing__PHeI1 span {
  font-weight: normal;
  line-height: 26px;
  font-size: 14px;
  text-transform: lowercase;

  color: #454452;
  margin-right: 13px;
}
.StaticStyles_privacy__1iSNg .StaticStyles_staticParagraph__tKXs3 {
  font-weight: normal;
  line-height: 26px;
  font-size: 14px;
}
.StaticStyles_privacy__1iSNg .StaticStyles_sectionsTitle__4CboN {
  margin: 48px 0 15px;
  font-weight: 500;
  line-height: 26px;
  font-size: 14px;
}
.StaticStyles_privacy__1iSNg h3.StaticStyles_staticSubTitle__3IrJ8 {
  font-weight: 500;
  line-height: 30px;
  margin-top: 32px;
  margin-bottom: 3px;
  text-align: left;
}
.StaticStyles_privacy__1iSNg.StaticStyles_staticContainerMobile__pKm7X h3.StaticStyles_staticSubTitle__3IrJ8 + p:first-child {
  margin-bottom: 20px;
}
.StaticStyles_privacy__1iSNg.StaticStyles_staticContainerMobile__pKm7X h3.StaticStyles_staticSubTitle__3IrJ8 {
  line-height: 23px;
  margin-bottom: 8px;
}
.StaticStyles_privacy__1iSNg.StaticStyles_staticContainerMobile__pKm7X h3[id="user_content"] {
  height: 68px;
}

/* .welcomePage {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #245995;
} */
.contactContainer {
  background: url(/static/media/contactus_bg.bf7de2e9.jpg) no-repeat center;
  background-size: cover;
  margin-bottom: -20px;
  padding: 90px 0 45px; }
  @media only screen and (max-width: 992px) {
    .contactContainer {
      padding-top: 40px; } }
  .contactContainer .formContainer {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto; }
    .contactContainer .formContainer .header {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: #626262;
      padding-bottom: 20px; }
      @media only screen and (max-width: 992px) {
        .contactContainer .formContainer .header {
          font-size: 18px;
          padding-bottom: 10px; } }
      @media only screen and (max-width: 767px) {
        .contactContainer .formContainer .header {
          font-size: 15px; } }
      .contactContainer .formContainer .header h1 {
        font-size: 70px;
        color: #20395c;
        font-weight: 900;
        margin-bottom: 30px; }
        @media only screen and (max-width: 992px) {
          .contactContainer .formContainer .header h1 {
            font-size: 50px;
            margin-bottom: 10px; } }
        @media only screen and (max-width: 767px) {
          .contactContainer .formContainer .header h1 {
            font-size: 40px; } }
    .contactContainer .formContainer .info {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: #626262;
      margin-top: 20px; }
      .contactContainer .formContainer .info a {
        font-size: 24px; }
        @media only screen and (max-width: 992px) {
          .contactContainer .formContainer .info a {
            font-size: 18px;
            padding-bottom: 10px; } }
        @media only screen and (max-width: 767px) {
          .contactContainer .formContainer .info a {
            font-size: 15px; } }
      @media only screen and (max-width: 992px) {
        .contactContainer .formContainer .info {
          font-size: 18px;
          padding-bottom: 10px; } }
      @media only screen and (max-width: 767px) {
        .contactContainer .formContainer .info {
          font-size: 15px; } }
  .contactContainer .row .inner-col {
    max-width: 50%;
    width: 100%; }
    @media only screen and (max-width: 640px) {
      .contactContainer .row .inner-col {
        max-width: 100%; } }
    .contactContainer .row .inner-col > div {
      width: 100%; }
  .contactContainer .btnCon {
    text-align: center; }
    .contactContainer .btnCon .primary-btn {
      background: #f58533;
      color: #ffffff;
      height: 60px;
      width: 205px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border-radius: 30px;
      margin: 0 auto; }
  .contactContainer .MuiOutlinedInput-inputMultiline-33 {
    min-height: 160px;
    overflow: auto; }
  .contactContainer .MuiInputBase-input-46,
  .contactContainer .MuiInputBase-multiline-44 {
    background: #ffffff; }
  .contactContainer .MuiFormLabel-root-16 {
    font-size: 14px; }

.form-group {
  margin-bottom: 20px; }
  .form-group > div {
    width: 100%; }

.tileWrapper {
  background: #132e53;
  max-width: 272px;
  width: 100%;
  padding: 25px 15px; }
  @media only screen and (max-width: 1199px) {
    .tileWrapper {
      max-width: calc(100vw / 4.4); } }
  @media only screen and (max-width: 639px) {
    .tileWrapper {
      display: flex;
      max-width: none;
      align-items: center;
      margin-bottom: 7px;
      padding: 12px 15px; } }
  .tileWrapper .img {
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 18px; }
    @media only screen and (max-width: 767px) {
      .tileWrapper .img {
        height: 40px;
        width: 40px;
        margin-bottom: 10px; } }
    @media only screen and (max-width: 639px) {
      .tileWrapper .img {
        margin: 0;
        height: 24px;
        width: 24px; } }
  .tileWrapper .text {
    font-size: 20px;
    color: white;
    text-align: center;
    max-width: 115px;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .tileWrapper .text {
        font-size: 14px; } }
    @media only screen and (max-width: 639px) {
      .tileWrapper .text {
        max-width: none;
        text-align: left;
        margin: 0 0 0 15px; } }

.sliderContainer {
  position: relative; }
  .sliderContainer .image {
    background-image: url(/static/media/slider_img.9c59f9bf.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px; }
    @media only screen and (max-width: 767px) {
      .sliderContainer .image {
        min-height: 200px; } }
  .sliderContainer .sliderText {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    color: #626262; }
    @media only screen and (max-width: 767px) {
      .sliderContainer .sliderText {
        position: relative;
        padding: 20px 0; } }
    .sliderContainer .sliderText h1 {
      max-width: 875px;
      width: 100%;
      margin: 0 auto 27px; }
      @media only screen and (max-width: 1023px) {
        .sliderContainer .sliderText h1 {
          margin-bottom: 8px; } }
    .sliderContainer .sliderText .textContainer {
      max-width: 1120px;
      width: 100%;
      margin: 0 auto; }
      .sliderContainer .sliderText .textContainer p {
        line-height: 24px; }
        @media only screen and (max-width: 1023px) {
          .sliderContainer .sliderText .textContainer p {
            font-size: 12px;
            line-height: 18px; } }
  .sliderContainer img {
    display: block;
    width: 100%; }

.info-container {
  background: #f58533;
  margin-bottom: 80px; }
  @media only screen and (max-width: 639px) {
    .info-container {
      margin-bottom: 30px; } }
  .info-container .container {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 639px) {
      .info-container .container {
        display: block; } }

.home-services h1 {
  max-width: 920px;
  width: 100%;
  margin: 0 auto 30px;
  text-align: center; }

.home-services .services-inner {
  display: flex;
  padding-bottom: 55px; }
  @media only screen and (max-width: 1023px) {
    .home-services .services-inner {
      display: block;
      padding-bottom: 20px; } }
  .home-services .services-inner .services {
    margin-right: 15px;
    flex: 1 1; }
    @media only screen and (max-width: 1023px) {
      .home-services .services-inner .services {
        margin-bottom: 20px;
        margin-right: 0; } }
    .home-services .services-inner .services:last-child {
      margin-left: 15px;
      margin-right: 0; }
      @media only screen and (max-width: 1023px) {
        .home-services .services-inner .services:last-child {
          margin-left: 0; } }
    .home-services .services-inner .services .header {
      display: flex;
      align-items: flex-start;
      padding-bottom: 23px;
      min-height: 122px; }
      @media only screen and (max-width: 1199px) {
        .home-services .services-inner .services .header {
          min-height: 120px; } }
      @media only screen and (max-width: 1023px) {
        .home-services .services-inner .services .header {
          min-height: auto; } }
      @media only screen and (max-width: 767px) {
        .home-services .services-inner .services .header {
          display: block; } }
      .home-services .services-inner .services .header .heading {
        font-size: 30px;
        color: #20395c;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-right: 17px; }
        @media only screen and (max-width: 1199px) {
          .home-services .services-inner .services .header .heading {
            font-size: 24px; } }
        .home-services .services-inner .services .header .heading:before {
          content: "";
          display: inline-flex;
          height: 60px;
          width: 10px;
          background: #f58533;
          margin-right: 17px; }
          @media only screen and (max-width: 1199px) {
            .home-services .services-inner .services .header .heading:before {
              height: 40px; } }
    .home-services .services-inner .services .body {
      font-size: 24px;
      line-height: 26px;
      color: #626262;
      padding-top: 21px; }
      @media only screen and (max-width: 1199px) {
        .home-services .services-inner .services .body {
          font-size: 20px;
          padding-top: 10px; } }
      @media only screen and (max-width: 767px) {
        .home-services .services-inner .services .body {
          font-size: 16px;
          line-height: normal; } }
  .home-services .services-inner .services-detail {
    padding: 40px 30px;
    background: #f58533;
    border-radius: 4px;
    min-height: calc(100% - 122px); }
    @media only screen and (max-width: 767px) {
      .home-services .services-inner .services-detail {
        padding: 15px; } }
    .home-services .services-inner .services-detail .serviceTile:last-child {
      padding-bottom: 0; }
  .home-services .services-inner .account .services-detail {
    background: #132e53; }

.get-started {
  border: 4px solid #d5d5d5;
  border-radius: 6px;
  padding: 30px 75px;
  text-align: center;
  color: #20395c;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 1023px) {
    .get-started {
      padding: 15px;
      font-size: 30px;
      line-height: normal;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 1023px) {
    .get-started {
      font-size: 20px; } }
  .get-started .text {
    padding-bottom: 19px; }
  .get-started .btn-outline {
    border-color: #f58533;
    border-width: 2px;
    color: #f58533;
    font-size: 20px;
    background: #FFF;
    text-transform: uppercase;
    padding: 12px 38px; }
    .get-started .btn-outline:hover {
      background: #FFF; }
    .get-started .btn-outline:focus {
      color: #f58533; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .row .inner-col {
    padding: 0 15px;
    max-width: 33.333333%;
    width: 100%; }

.get-access h1 {
  color: #20395c; }

.get-access .inner-col {
  margin-bottom: 30px; }
  @media only screen and (max-width: 1023px) {
    .get-access .inner-col {
      max-width: 50%; } }
  @media only screen and (max-width: 767px) {
    .get-access .inner-col {
      max-width: 100%; } }
  .get-access .inner-col:nth-child(2n+1) .inner {
    background: #fef4ed; }
  .get-access .inner-col:nth-child(2n) .inner .heading:after {
    background: #fef4ed; }
  .get-access .inner-col .inner {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 18px 16px 30px;
    text-align: center;
    height: 100%; }
    .get-access .inner-col .inner .number {
      height: 55px;
      width: 55px;
      background: #eaeaea;
      color: #20395c;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 5px;
      font-weight: 700; }
    .get-access .inner-col .inner img {
      max-height: 102px;
      margin-bottom: 30px; }
    .get-access .inner-col .inner .heading {
      color: #20395c;
      font-size: 20px;
      font-weight: 700; }
      .get-access .inner-col .inner .heading:after {
        display: block;
        content: "";
        height: 2px;
        width: 130px;
        background: #FFF;
        margin: 15px auto; }
    .get-access .inner-col .inner .text {
      max-width: 310px;
      width: 100%;
      margin: 0 auto;
      color: #626262;
      line-height: 24px;
      font-weight: 700; }

.serviceTile {
  display: flex;
  padding-bottom: 20px; }
  .serviceTile .number {
    height: 60px;
    width: 75px;
    background: #f5f5f5;
    color: #626262;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 1023px) {
      .serviceTile .number {
        height: 50px;
        width: 60px;
        font-size: 20px; } }
  .serviceTile .text {
    font-size: 17px;
    color: #ffffff;
    flex: 1 1;
    display: flex;
    align-items: center;
    margin-left: 30px;
    border-bottom: 1px solid #ffffff; }
    @media only screen and (max-width: 1023px) {
      .serviceTile .text {
        font-size: 14px;
        margin-left: 10px; } }
    @media only screen and (max-width: 767px) {
      .serviceTile .text {
        padding-bottom: 10px; } }

.faqContainer {
  background: url(/static/media/faq_bg.626fd94d.jpg) no-repeat center;
  background-size: cover;
  padding: 145px 0 80px;
  margin-bottom: -20px; }
  @media only screen and (max-width: 1023px) {
    .faqContainer {
      padding: 30px 0; } }
  .faqContainer .accordion {
    border: none; }
    .faqContainer .accordion .accordion__item {
      font-size: 14px;
      font-weight: 700;
      color: #626262;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 22px; }
      .faqContainer .accordion .accordion__item:focus {
        outline: none; }
      .faqContainer .accordion .accordion__item + .accordion__item {
        border-top: none; }
      .faqContainer .accordion .accordion__item .accordion__heading:focus {
        outline: none; }
      .faqContainer .accordion .accordion__item .accordion__button {
        color: #20395c;
        position: relative;
        padding: 15px 35px 15px 25px;
        background: #f5f5f5; }
        .faqContainer .accordion .accordion__item .accordion__button:focus {
          outline: none; }
        .faqContainer .accordion .accordion__item .accordion__button:before {
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          position: absolute;
          right: 25px;
          margin-right: 0;
          top: 15px; }
      .faqContainer .accordion .accordion__item .accordion__panel {
        padding: 25px; }
  .faqContainer .last-text {
    font-size: 14px;
    font-weight: 700;
    padding-top: 20px; }
    .faqContainer .last-text a {
      color: #132e53; }

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  -webkit-transform: rotate(-135deg) !important;
          transform: rotate(-135deg) !important;
  top: 18px !important; }

.BaseContainer_baseContainer__UD96x {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}
.BaseContainer_container__3qSKE {
  display: flex;
  align-items: stretch;
  margin: 40px auto;
  width: 100%;
  flex: 1 1;
  max-width: 960px !important;
}


.JoinPage_joinPage__2mLsu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0 20px;
  min-height: calc(100vh - 90px);
}
.JoinPage_joinPage__2mLsu p {
  font-size: 14px;
}
.JoinPage_joinPageMobile__3QP7q {
  min-height: 100vh;
}
.JoinPage_verify__JvCzJ {
  background: #FFE0C7;
}
.JoinPage_joinPageMobile__3QP7q {
  padding: 30px 0;
}
.JoinPage_joinPageMobile__3QP7q p {
  margin-left: 10px;
  margin-right: 10px;
}
.JoinPage_JoinScreenContainer__3f__h {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Welcome screen*/
.JoinPage_joinFormContainer__u6Mqy {
  width: 300px;
}
.JoinPage_or__Uzegn {
  font-size: 14px;
  color: #454452;
  margin: 20px 0;
}
.JoinPage_JoinScreenTitle__1Mgih {
  color: #245995;
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  margin: 30px -50px;
}
.JoinPage_JoinScreenTitleMobile__1-vUK {
  font-size: 24px;
  line-height: 29px;
  margin: 30px 0;
}
.JoinPage_button__1PsAm {
  margin-top: 12px;
}

/*Join Form*/
.JoinPage_joinForm__38Izq {
  width: 300px;
}
.JoinPage_joinFormMobile__3xqSv {
  margin-top: 33px;
}
.JoinPage_formItems__33cPT>div {
  margin-bottom: 36px;
}
.JoinPage_formItems__33cPT>div:last-child {
  margin: 0;
}
.JoinPage_submitButton__3VzIY {
  max-width: 230px;
  margin: 36px auto 0;
}
.JoinPage_joinFooter__3RucS {
  margin: 0;
  color: #5c5b66;
  font-size: 14px;
  line-height: 16px;
}
.JoinPage_checkBoxContainer__12RoD {
  text-align: start;
  margin-top: 36px;
}
.JoinPage_checkBoxContainer__12RoD label {
  margin: 0;
}
.JoinPage_checkBoxContainer__12RoD label>span:first-child {
  margin-right: 9px;
  padding: 0;
}
.JoinPage_checkBoxLabel__1-iYO {
  font-size: 14px;
  line-height: 21.7px;
  color: #5c5b66;
}
.JoinPage_joinPage__2mLsu .JoinPage_modalFooter__1HQxK {
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #454452;
}

/*Registration Form*/
.JoinPage_registrationForm__1xsYZ {
  text-align: left;
  width: 300px;
}
.JoinPage_registrationForm__1xsYZ>div {
  margin-bottom: 36px;
}
.JoinPage_registrationForm__1xsYZ>div:last-child {
  margin: 0;
}
.JoinPage_registrationForm__1xsYZ .JoinPage_formItems__33cPT {
  margin-bottom: 36px; 
}
.JoinPage_registrationFormMobile__rzRmz {
  margin-top: 33px;
}
.JoinPage_joinError__3iBo1 {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto 20px;
  line-height: 1em;
}



.JoinPage_verificationContainer__XdJL- {
  margin-top: 27px;
}
.JoinPage_verificationContainerMobile__1zNhw {
  margin-top: 8px;
}
.JoinPage_verificationContainer__XdJL- h2 {
  margin: 0 0 37px;
  color: #245995;
  font-weight: bold;
  font-size: 38px;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.JoinPage_verificationContainerMobile__1zNhw h2 {
  margin: 0 0 24px;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.JoinPage_verificationContainer__XdJL- h3 {
  margin: 0 0 8px;
  color: #454452;
  font-weight: 400;
  line-height: 33px;
  font-size: 20px;
}
.JoinPage_verificationContainerMobile__1zNhw h3 {
  margin: 0 auto 62px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.JoinPage_verificationContainer__XdJL- p {
  margin: 40px auto 0;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 209px;
}
.JoinPage_verificationContainer__XdJL- p:last-child {
  margin: 8px auto 0;
}
/* p.verificationDescription {
  height: 53px;
  margin: 40px 0;
  color: #454452;
  font-size: 20px;
  max-width: fit-content;
  font-weight: 400;
  line-height: 26px;
}
.verificationContainerMobile p.verificationDescription {
  margin: 42px auto 63px;
  color: #454452;
  font-size: 18px;
  line-height: 23.6px;
  max-width: 220px;
} */
.LoginPage_loginPage__1X4Ky {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0;
  min-height: calc(100vh - 90px);
}
.LoginPage_loginPage__1X4Ky p {
  font-size: 14px;
}
.LoginPage_loginPageMobile__3szxj {
  min-height: 100vh;
}
.LoginPage_resend__glBHl {
  background: #FFE0C7;
}
.LoginPage_loginPageMobile__3szxj {
  padding: 30px 0;
}
.LoginPage_LoginScreenTitle__2w1Vt {
  color: #245995;
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  margin: 30px 0;
}
.LoginPage_LoginScreenTitleMobile__1PI9C {
  font-size: 24px;
  line-height: 29px;
}
.LoginPage_LoginScreenContainer__23UqP > p {
  font-size: 14px;
  color: #454452;
  margin: 20px 0;
}
.LoginPage_button__WMSgL {
  margin-top: 12px;
}
/*Welcome screen*/
.LoginPage_loginPageContent__3adMi {
  display: flex;
  flex-direction: column;
}
.LoginPage_title__18hxk {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  margin: 0;
}
.LoginPage_loginPage__1X4Ky .LoginPage_footer__3bCSh {
  color: #5c5b66;
  font-size: 14px;
  margin: 0;
}
.LoginPage_loginPage__1X4Ky .LoginPage_modalFooter__28gEU {
  margin: 45px 0 25px;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #454452;
}
.LoginPage_modalButton__2J9d7 {
  margin-bottom: 0 !important;
}
/*Login Form*/
.LoginPage_loginForm__3khyy {
  width: 300px;
}
.LoginPage_loginFormMobile__1Ozq4 {
  margin-top: 33px;
}
.LoginPage_formItems__25yOn>div {
  margin-bottom: 36px;
}
.LoginPage_formItems__25yOn>div:last-child {
  margin-bottom: 2px;
}

.LoginPage_submitButton__P84vc {
  max-width: 136px;
  margin: 24px auto 0;
}

.LoginPage_loginFooter__1KKB_ {
  margin: 18px auto 0;
  color: #5c5b66;
  font-size: 14px;
}
.LoginPage_checkBoxContainer__3RyBa {
  margin-top: 14px;
  font-size: 14px;
  text-align: start;
  color: #5c5b66;
}
.LoginPage_checkBoxLabel__-XuAU {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .56px;
  color: #5c5b66;
  margin-top: 16px;
  margin-left: -19px;
  text-transform: capitalize;
}
.LoginPage_errorContainer__1SixT {
  position: relative;
}
.LoginPage_loginError__1Blw4 {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 8px;
  line-height: 1em;
  position: absolute;
  top: -15px;
  text-align: left;
}
.LoginPage_LoginScreenContainer__23UqP {
  width: 300px;
}

.LoginPage_verificationContainer__3dq_L {
  margin-top: 57px;
  flex: 1 1;
}
.LoginPage_verificationContainerMobile__1iXrV {
  margin-top: 30px;
}
.LoginPage_verificationContainer__3dq_L h1 {
  margin: 0 0 37px;
  color: #245995;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.LoginPage_verificationContainerMobile__1iXrV h1 {
  margin: 0 0 24px;
  font-size: 32px;
  letter-spacing: .96px;
}
.LoginPage_verificationContainer__3dq_L h3 {
  margin: 0 auto 48px;
  color: #5c5b66;
  font-size: 26px;
  font-weight: 400;
  line-height: 32.6px;
  max-width: 570px;
}
.LoginPage_verificationContainerMobile__1iXrV h3 {
  margin: 0 auto 62px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.LoginPage_verificationContainer__3dq_L>svg {
  margin-left: -40px;
}
.LoginPage_verificationContainer__3dq_L p {
  margin: 0 auto;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 209px;
}
p.LoginPage_verificationDescription__19fN7 {
  height: 53px;
  margin: 40px auto 42px;
  color: #454452;
  font-size: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 400;
  line-height: 26px;
}
.LoginPage_verificationContainerMobile__1iXrV p.LoginPage_verificationDescription__19fN7 {
  margin: 42px auto 63px;
  color: #454452;
  font-size: 18px;
  line-height: 23.6px;
  max-width: 220px;
}

/*Registration Form*/
.LoginPage_registrationForm__3MpiL {
    text-align: left;
    width: 300px;
  }
  .LoginPage_registrationForm__3MpiL>div {
    margin-bottom: 36px;
  }
  .LoginPage_registrationForm__3MpiL>div:last-child {
    margin: 0;
  }
  .LoginPage_registrationForm__3MpiL .LoginPage_formItems__25yOn {
    margin-bottom: 36px; 
  }
  .LoginPage_registrationFormMobile__3zrna {
    margin-top: 33px;
  }
.ForgotPage_forgotPage__1w5iP {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  text-align: center;

  flex-direction: column;
  padding: 40px 0;
  min-height: calc(100vh - 90px);
}

.ForgotPage_forgot__10k1p {
  background: #FFE0C7;
}
.ForgotPage_forgotPageMobile__zOB_J {
  min-height: 100vh;
  padding-top: 30px;
}

.ForgotPage_forgotPage__1w5iP > p {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .56px;
  margin: 0;
}

.ForgotPage_forgotForm__36qA4 {
  width: 300px;
}
.ForgotPage_submitButton__6vHYT {
  max-width: 176px;
  margin: 36px auto 0;
}
.ForgotPage_forgotError__3ZDdF {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto;
  line-height: 1em;
  text-align: left;
}

.ForgotPage_forgotContainer__Yt3FZ {
  margin-top: 57px;
}
.ForgotPage_forgotContainerMobile__2xpEQ {
  margin-top: 38px;
}
.ForgotPage_forgotContainer__Yt3FZ h1 {
  margin: 0 0 27px;
  color: #245995;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.ForgotPage_forgotContainerMobile__2xpEQ h1 {
  margin: 0 0 24px;
  font-size: 32px;
  letter-spacing: .96px;
}
.ForgotPage_forgotContainer__Yt3FZ h3 {
  margin: 0 0 46px;
  color: #5c5b66;
  font-size: 26px;
  font-weight: 400;
  line-height: 32.6px;
}
.ForgotPage_forgotContainerMobile__2xpEQ h3 {
  margin: 0 auto 26px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.ForgotPage_forgotContainer__Yt3FZ>svg {
  margin-left: -40px;
}
.ForgotPage_forgotContainer__Yt3FZ p {
  margin: 0 auto;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 260px;
}
.ForgotPage_forgotContainer__Yt3FZ .ForgotPage_Button__KcGiP {
  margin: 44px auto 90px;
  max-width: 300px;
}
.ForgotPage_forgotContainerMobile__2xpEQ .ForgotPage_Button__KcGiP {
  margin: 42px auto 59px;
}
.ForgotPage_forgotContainer__Yt3FZ .ForgotPage_Button__KcGiP p {
  color: #fff;
}
.AccountContainer_accountContainer__u1LM6 {
  min-height: 100%;

  display: flex;
  justify-content: start;
  flex-direction: column;
  
}
.signUpContainer {
  background: #FFF url(/static/media/signUpBg.b477c177.jpg) no-repeat;
  background-size: cover;
  padding: 30px 0;
  flex: 1 1; }
  .signUpContainer .modalFooter {
    margin: 20px 0; }
  .signUpContainer .inner {
    background: url(/static/media/signUp_container_bg.a531e0ab.png) repeat;
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 15px 70px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
    @media only screen and (max-width: 767px) {
      .signUpContainer .inner {
        padding: 15px; } }
    .signUpContainer .inner h1 {
      color: #20395c;
      font-size: 70px;
      padding-bottom: 20px; }
      @media only screen and (max-width: 1023px) {
        .signUpContainer .inner h1 {
          font-size: 50px;
          padding-bottom: 10px; } }
      @media only screen and (max-width: 1023px) {
        .signUpContainer .inner h1 {
          font-size: 40px; } }
    .signUpContainer .inner .header {
      color: #626262;
      font-size: 24px;
      font-weight: 700;
      padding-bottom: 35px; }
      @media only screen and (max-width: 1023px) {
        .signUpContainer .inner .header {
          font-size: 18px;
          padding-bottom: 20px; } }
      @media only screen and (max-width: 767px) {
        .signUpContainer .inner .header {
          font-size: 16px; } }
  .signUpContainer .captchaContainer {
    padding-bottom: 25px;
    display: inline-block; }
  .signUpContainer .social-btns {
    max-width: 310px;
    width: 100%;
    margin: 0 auto; }
    .signUpContainer .social-btns .primary-btn {
      width: 100%;
      margin-bottom: 18px; }
  .signUpContainer .have-account,
  .signUpContainer .terms {
    color: #626262;
    padding-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .signUpContainer .have-account,
      .signUpContainer .terms {
        padding-bottom: 20px; } }
    .signUpContainer .have-account a,
    .signUpContainer .terms a {
      color: #626262; }
  .signUpContainer .terms {
    padding-bottom: 0; }
  .signUpContainer.signin .inner {
    max-width: 570px; }
    .signUpContainer.signin .inner .have-account {
      padding-bottom: 0; }

.primary-btn {
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-height: 48px;
  border-radius: 25px;
  background: #f58533; }
  @media only screen and (max-width: 767px) {
    .primary-btn {
      font-size: 14px; } }
  .primary-btn:hover {
    background: #f58533; }
  .primary-btn.blue {
    background: #132e53; }
    .primary-btn.blue:hover {
      background: #132e53; }
  .primary-btn img {
    margin-right: 12px; }

.joinError {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto 20px;
  line-height: 1em; }

@font-face {
  font-family: 'AntennaCond';
  src: url(/static/media/AntennaCond.80207877.eot);
  src: url(/static/media/AntennaCond.80207877.eot) format("embedded-opentype"), url(/static/media/AntennaCond.fc321d79.woff2) format("woff2"), url(/static/media/AntennaCond.470b178a.woff) format("woff"), url(/static/media/AntennaCond.fca83c07.ttf) format("truetype"), url(/static/media/AntennaCond.d6a942e3.svg#AntennaCond) format("svg"); }

html {
  height: 100%; }

body {
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0; }

#root {
  height: 100%; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

input,
select,
textarea,
button {
  font-family: 'Lato', sans-serif; }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

a {
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
  overflow: hidden;
  color: #245995; }

a:focus {
  color: #1D3796; }

a[tabIndex]:focus {
  color: #1D3796;
  outline: none; }

a:hover {
  cursor: pointer; }

a:disabled {
  color: #b4c6dc; }

button {
  border: 0;
  padding: 0;
  background-color: transparent; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

a[disabled] {
  pointer-events: none;
  color: #b4c6dc; }

a[disabled] svg path {
  fill: #b4c6dc; }

.m-v-50 {
  margin: 50px 0; }

.m-v-20 {
  margin: 20px 0; }

.m-top-20 {
  margin: 20px 0 0 0; }

.text-center {
  text-align: center; }

h1, h2, h3, h4, h5 {
  color: #245995; }

h1 {
  font-size: 40px; }
  @media only screen and (max-width: 1023px) {
    h1 {
      font-size: 30px; } }
  @media only screen and (max-width: 639px) {
    h1 {
      font-size: 24px; } }

.container {
  max-width: 1200px !important;
  width: 100%; }
  .container > span {
    display: none !important; }

.orange {
  color: #f58533; }

