.headerContainer {
  height: 125px;
  background: #fff;
  border-bottom: 2px solid #132e53;
  width: 100%;
  box-shadow: 0 9px 9px rgba(0, 0, 0, 0.3);

}
.headerContainer.isOpen {
  position: relative;
}
.headerContainer.isOpen:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(241, 126, 33, 0.65);
}
.hideMobile {
  display: none;
}
.headerMobile {
  height: 100px;
}
.headerMobile .contentContainer {
  padding: 0 30px !important;
}
.headerMobile .rightSide button {
  margin-left: 22px;
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
}
.headerMobile .contentContainer {
  height: 70px;
}
.leftSide {
  display: flex;
  align-items: center;
}
.leftSide svg {
  height: 27px;
}
.rightSide {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
