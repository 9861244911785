.contactContainer {
  background: url("../../images/contactus_bg.jpg") no-repeat center;
  background-size: cover;
  margin-bottom: -20px;
  padding: 90px 0 45px;

  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }

  .formContainer {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    .header {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: #626262;
      padding-bottom: 20px;

      @media only screen and (max-width: 992px) {
        font-size: 18px;
        padding-bottom: 10px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 15px;
      }

      h1 {
        font-size: 70px;
        color: #20395c;
        font-weight: 900;
        margin-bottom: 30px;

        @media only screen and (max-width: 992px) {
          font-size: 50px;
          margin-bottom: 10px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 40px;
        }
      }
    }
    .info {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: #626262;
      margin-top: 20px;

      a {
        font-size: 24px;
        @media only screen and (max-width: 992px) {
          font-size: 18px;
          padding-bottom: 10px;
        }
  
        @media only screen and (max-width: 767px) {
          font-size: 15px;
        }
      }

      @media only screen and (max-width: 992px) {
        font-size: 18px;
        padding-bottom: 10px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .row {
    .inner-col {
      max-width: 50%;
      width: 100%;

      @media only screen and (max-width: 640px) {
        max-width: 100%;
      }

      > div {
        width: 100%;
      }
    }
  }

  .btnCon {
    text-align: center;

    .primary-btn {
      background: #f58533;
      color: #ffffff;
      height: 60px;
      width: 205px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border-radius: 30px;
      margin: 0 auto;
    }
  }

  .MuiOutlinedInput-inputMultiline-33 {
    min-height: 160px;
    overflow: auto;
  }

  .MuiInputBase-input-46,
  .MuiInputBase-multiline-44 {
    background: #ffffff;
  }

  .MuiFormLabel-root-16 {
    font-size: 14px;
  }
}

.form-group {
  margin-bottom: 20px;

  > div {
    width: 100%;
  }
}