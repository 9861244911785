.userContainer {
  display: flex;
  margin: 40px auto;
  width: 100%;
  padding: 0 15px;
  flex: 1;
  max-width: 1440px;
  overflow-y: hidden;
}
.pageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  margin-left: 0 !important;
}
.userContainerMobile {
  flex-direction: column;
}
.navigation {
  width: 200px;
  border-right: 1px solid #e4e4e4;
}
.userContainerMobile .navigation {
  width: 100%;
  margin: 0;
  border-right: 0;
  border-bottom: 1px solid #e4e4e4;
}
.navigation a:focus {
  color: #245995;
}

.nav {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}
.nav>.link {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  margin-right: 15px;
}
.nav>.selected {
  color: #245995;
  text-decoration: underline;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 40px;
}
.userContainerMobile .content {
  margin: 37px 0 40px;
}
.link {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  display: flex;
  margin-bottom: 20px;
}
.link > svg {
  margin-right: 12px;
}
.link:hover {
  color: #245995;
}
.link:hover>svg path {
  fill: #245995;
}
.selected {
  color: #245995;
}
.selected>svg path {
  fill: #245995;
}
.contentTitle {
  margin-bottom: 65px;
}
.contentTitleMobile {
  margin-bottom: 39px;
}
.title {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.balance {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #245995;
}
.formContainer h1.phone {
  margin: 30px 0 30px -30px;
}
.tip {
  margin: -20px 0 20px;
}
.subTitle {
  margin: 0 30px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #858297;
}
.info {
  margin: 0 30px 30px;
  font-weight: 500;
  line-height: 26px;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;
}
.talk {
  margin: 0;
  font-weight: normal;
  line-height: 26px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #454452;
}
.talk a {
  color: #245995;
  font-size: 14px;
  font-weight: 400;
}
.settingsPageContainer {
  flex: 1;
  display: flex;
  width: 100%;
}
.blockContainer {
  border: 1px solid #b7b3ce;
  margin-left: 30px;
  padding: 28px 40px 26px;
  flex: 1;
  /* width: 440px; */
}
.blockContainerMobile {
  width: 300px;
  padding: 28px 20px 26px;
} 
.forgot {
  background: #FFE0C7;
  border: 1px solid #245995;
  padding: 15px 27px;
}
.blockContainer a {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;

  display: flex;
  align-items: center;
}
.blockContainer h3 {
  margin: 0 0 4px;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #245995;
  height: auto;
  text-align: left;
}
.blockContainer p {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #5C5B66;
}

.blockContainer a > svg {
  margin-left: 10px;
}
.form > div {
  position: relative;
  margin: 0 0 35px;
}
.userContainer .form {
  margin-left: 30px;
} 
.userContainerMobile .form {
  margin-right: 30px;
}
.Button {
  max-width: 136px;
  float: right;
  margin-top: 36px;
}
.userContainerMobile .Button {
  margin: 36px auto 0;
  float: none;
}
.ButtonCancel {
  background-color: #b1afba;
}

.DropdownContainer {
  width: 100%;
}
.DropdownContainer>div:nth-child(3)>div>div:last-child {
  border-bottom: 0 !important;
}
/*custom scroll*/
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar {
  width: 8px;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-track {
  background: transparent;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar>* {
  background:transparent;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}
.error {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
.confirmationContainer {
  margin: 36px 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.success {
  flex: 1;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: .04em;
  color: #245995;
}

.containerX {
  display: flex;
  align-items: stretch;
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 960px !important;
}
.noMargin {
  margin: 0 auto;
  padding: 0 !important;
}

.textBoxContainer {
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding-top: 20px;
  background: #fff;
}
.textBoxContainer .textInput {
  max-height: 126px;

  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;

  border: 1px solid #E5E4EE;
  border-radius: 6px;
  padding: 14px;
  flex: 1;
  
  word-break: break-all;
  resize: none;
  overflow: auto;
}
.textBoxContainer .textInput:hover {
  border: 1px solid #B7B3CE;
}
.textBoxContainer .textInput:focus {
  border: 1px solid #454452;
}
.textBoxContainer .textInput::placeholder {
  color: #B1AFBA;
}
.textInput::-webkit-scrollbar {
  width: 4px;
}
.textInput::-webkit-scrollbar-track {
  background: transparent;
}
.textInput::-webkit-scrollbar>* {
  background:transparent;
}
.textInput::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.textInput::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.textInput::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.textSubmitButton {
  margin-left: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #245995;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textSubmitButton:disabled {
  background: #DFE7F2;
}
.textSubmitButton:disabled svg path {
  fill: #B4C6DC;
}
.textSubmitButton > svg {
  margin-left: -3px;
}

.noMessages {
  margin: auto;
  width: 100%;
  max-width: 390px;
  height: 248px;
  padding: 46px 64px 51px;
  border: 1px solid #E5E4EE;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
}
.noMessages h1 {
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #5C5B66;
}
.noMessages>p {
  margin: 8px 0 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #5C5B66;
}
.noMessages .ButtonLink {
  margin: 0 auto;
  max-width: 176px;
}

.registrationContainer {
  max-width: 528px !important;
  margin-bottom: 40px;
  -webkit-overflow-scrolling: touch;
}
.registrationContainerMobile {
  max-width: 275px !important;
}
.registrationContainer .logo {
  text-align: center;
}
.container {
  display: flex;
}
.container>div {
  flex: 1;
}
.dashboardContainerMobile .container {
  flex-direction: column;
}
.container h6 {
  color: #245995;
  margin: 0 0 12px;
}
.dashboardContainerMobile .container>div>div {
  width: 100% !important;
}
.managerContainerMobile {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 10px;
}
.wrapper {
  /* overflow-y: hidden; */
  overflow-x: visible;
  padding-bottom: 10px;
  margin-bottom: 35px;
}
.billContainer {
  max-width: 670px;
}
.billContainer .subTitle {
  margin-left: 0;
}
.rateContainerMobile .table,
.managerContainerMobile .table {
  min-width: 650px;
}
.dashboardContainer,
.managerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboardContainerMobile,
.managerContainerMobile {
  min-height: 200px;
}
.managerContainer .containerTitle {
  padding-bottom: 20px;
  position: relative;
}
.managerContainer .modal section {
  min-height: 450px;
}
.managerContainer .modal section>div {
  display: flex;
  flex-direction: column;
}
.status {
  position: absolute;
  bottom: 0;
  color: #245995;
}
.table {
  display: flex;
  flex-direction: column;
}  
.table .header,
.table .row {
  display: flex;
}
.row {
  font-size: 14px;
}
.table .header div,
.table .row>div {
  flex: 1;
  padding: 5px 10px;
  border: 1px solid #e4e4e4;
  min-width: 60px;
}
.table .header div:first-child,
.table .row>div:first-child {
  min-width: 200px;
}
.userContainerMobile .table .row>div {
  min-width: 120px;
}
.billContainer .table .header div,
.billContainer .table .row>div {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}
.userContainerMobile .table .header div {
  min-width: 120px;
}
.table .header .remove,
.table .row .remove {
  background-color: white;
  text-align: right;
  font-weight: 700;
}
.remove button {
  font-size: 14px;
  margin: 0 auto;
  display: block;
  color: #245995;
}
.remove button:hover {
  cursor: pointer;
}
.row.active {
  color: #b1afba;
}
.table .header div { 
  background-color: rgb(238, 238, 238);
  color: #245995;
}
.billContainer .table .header > div {
  background-color: rgb(238, 238, 238);
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.table .header > div svg {
  min-width: 20px;
}

/*Mobile scrooling*/
.managerContainerMobile::-webkit-scrollbar {
  width: 2px;
}
.managerContainerMobile::-webkit-scrollbar-track {
  background: transparent;
}
.managerContainerMobile::-webkit-scrollbar>* {
  background:transparent;
}
.managerContainerMobile::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.managerContainerMobile::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.managerContainerMobile::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.modal section {
  width: 600px;
  max-height: 500px;
}
.registrationContainerMobile .modal section {
  width: 300px;
  max-height: 500px;
}
.modal form {
  flex: 1;
  width: 100%;
  max-width: 350px;
  margin: 20px auto;
}
.managerContainer .modal form>div {
  margin-bottom: 35px;
}
.registrationContainer .Button,
.managerContainer .Button {
  margin-top: 20px;
  float: initial;
}
.registrationContainer .modal .error {
  margin: 0;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
}
.managerContainer .error {
  margin: 0;
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
}
.managerContainer .modal form>div:last-child {
  margin-bottom: 20px;
}
.modal section h3 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.managerContainerMobile .modal section {
  width: 300px;
  max-height: 500px;
  padding: 23px 18px 26px;
}
.techFormContainer .table .row2>div:first-child {
  flex: 0 0 33.3333%;
}
.datePicker>div>div {
  width: 100% !important;
}
.datePicker>div>div>span {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.datePicker>div>div>div>div {
  width: 100%;
}
.datePicker>div>div+span {
  position: absolute;
  color: #f44336 !important;
  font-size: 0.75rem !important;
  margin-top: 8px !important;
  line-height: 1em;
}
.datePicker>div>div:before {
  border-bottom: 1px solid #b7b3ce !important;
}
.datePicker>div>div input {
  width: 100% !important;
  padding: 14px 0 10px !important;
  color: #454452 !important;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px !important;
  letter-spacing: 0.04em !important;
}
.popper {
  min-width: 200px !important;
}
.modal .containerBtn {
  display: flex;
  margin: 40px 0 0;
  justify-content: space-between;
}
.modal .containerBtn button {
  margin: 0;
  max-width: 100px;
}
.modal .modalTitle {
  margin: 0;
  font-size: 14px;
}
.modal .modalText {
  margin: 40px 0 20px;
  font-size: 14px;
  line-height: 23.4px;
  color: #b1afba;
  position: relative;
}
.modal .modalPhoneNumber {
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #454452;
}
.containerTitle {
  display: flex;
  margin-bottom: 22px;
}
.techFormContainer {
  margin: 40px 0;
  border-top: 1px solid #454452;
  padding-top: 20px;
}

.dashboardContainerMobile .containerTitle {
  flex-direction: column;
}
.dashboardContainerMobile .container>div {
  margin-bottom: 20px;
}
.containerTitle .title {
  flex: 1;
}
.addButton {
  max-width: 100px;
  text-transform: initial;
}
.rateContainer .container {
  justify-content: space-between;
}
.rateContainer .container a {
  line-height: 20px;
}
.rateContainerMobile .container {
  flex-direction: column;
}
.staticRate {
  margin: 30px 0;
}
.staticRate h1,
.staticRate .container {
  margin: 30px;
}
.rateContainerMobile.staticRate h1,
.rateContainerMobile.staticRate .container {
  margin-left: 0px;
}
.rateContainerMobile.staticRate {
  margin: 30px;
}
.staticRate .table {
  margin: 0 30px;
}
.rateContainerMobile.staticRate .table {
  margin: 0;
}
.payContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.payContainer form>div {
  margin-bottom: 20px;
}
.payContainer .infoContainer {
  position: relative;
  height: 17px;
  margin: 0;
}
.payContainer .infoContainer span {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
}
.error {
  color: #f44336;
}
.loading {
  color: #b1afba;
}
.updated {
  color: #245995;
}
.loader {
  position: absolute;
}
.body {
  min-height: 20px;
}
.payContainer form>.checkbox {
  margin-left: -12px;
  margin-bottom: 0;
}
.checkbox > div {
  display: inline-flex;
  align-items: center;
}
.label {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.payContainer .Button {
  margin-top: 19px;
  float: left;
}
.payContainer .Button:last-child {
  margin-top: 19px;
  float: right;
}
.paymentContainer .paymentForm {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}
.paymentContainer .paymentForm .Button {
  float: none;
  margin: 0 0 0 20px;
}


.invoice {
  line-height: 24px;
}
.invoice header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #b1afba;
}
.paymentContainerMobile .invoice header>div:first-child {
  margin-bottom: 20px;
}
.paymentContainerMobile .invoice header {
  flex-direction: column;
}
.invoice h1 {
  text-align: center;
  color: #454452;
  font-size: 24px;
}
.invoice .to {
  margin: 20px 0 40px;
  display: flex;
  justify-content: space-between;
}
.to .company {
  margin-left: 25px;
}
.paymentContainer .table .row:first-child {
  font-weight: bold;
}
.paymentContainer .table .row:nth-child(2)>div:first-child {
  border-right: 0 !important;
}
.paymentContainer .table .row:nth-child(2)>div:nth-child(2) {
  border-left: 0 !important;
  border-right: 0 !important;
  text-align: right;
}
.paymentContainer .table .row:nth-child(2)>div:nth-child(3) {
  border-left: 0 !important;
  text-align: right;
}
.paymentContainer .table .row:nth-child(3)>div:nth-child(2) {
  border-right: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.paymentContainer .table .row:nth-child(3)>div:nth-child(2)>div:last-child {
  border-top: 1px solid #e4e4e4;
  margin: 0 -10px;
  padding-left: 10px;
}
.paymentContainer .table .row:nth-child(3)>div:nth-child(3) {
  border-left: 0 !important;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.paymentContainer .table .row:nth-child(3)>div:nth-child(3)>div:last-child {
  border-top: 1px solid #e4e4e4;
  margin: 0 -10px;
  padding-right: 10px;
}
.paymentContainer .table .row>div:first-child {
  flex: 0 0 60%;
}

.pagination {
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}
.pagination>*{
  list-style-type: none;
  line-height: 23.4px;
}
.pagination>* a {
  text-decoration: none;
  font-size: 18px;
}
.page,
.break {
  margin-right: 10px;
}
.page>a {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
} 
.active {
  text-decoration: underline;
}
.active>a {
  color: #245995;
  font-weight: bold;
}
.prev {
  margin-right: 20px;
}
.next {
  margin-left: 10px;
}

/* Search Form styles */
.searchForm {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

.searchForm > div {
  max-width: 250px;
  margin-right: 30px;
}

.searchButton {
  max-width: 100px;
  margin-right: 20px;
}