.dropdownContainer {
  position: relative;
}
.value {
  white-space: nowrap;
}
.value:hover {
  cursor: pointer;
}
.menu {
  position: absolute;
  top: 0;
  right: 0;
}
.menuContainer {
  position: relative;
  padding: 40px 40px 35px; 
  height: 100%;
}
.menuContainer > svg {
  position: absolute;
  top: -8px;
  left: 0;
}
