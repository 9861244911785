// colors that are being used
$orange: #f58533;
$white: #FFF;
$blue: #29649f;
$blue_one: #132e53;

img {
  max-width: 100%;
}

.headerTop {
  background: $orange;

  .container {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 400px) {
      padding-right: 0 !important;
    }
  }

  a {
    color: $white;
    text-decoration: none;
  }

  .left {
    font-family: 'AntennaCond';
    color: $white;
    display: flex;

    > * {
      display: flex;
      margin-right: 12px;
      align-items: center;

      @media only screen and (max-width: 400px) {
        font-size: 12px;
      }

      &:last-child {
        margin-right: 0;
      }

      > img {
        margin-right: 6px;
        
        @media only screen and (max-width: 400px) {
          max-width: 14px;
        }
      }
    }

    a {
      text-transform: lowercase;
    }
  }
  
  .right {
    margin: 0 0 0 auto;
    display: flex;
    list-style: none;

    li {
      border-left: 1px solid $white;
      
      a {
        display: block;
        padding: 12px 10px;

        @media only screen and (max-width: 400px) {
          padding: 8px 10px;
        }
      }
    }
  }
}

.headerMain {
  border-bottom: 2px solid $blue_one;
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      @media only screen and (max-width: 991px) {
        max-width: 180px;
      }
    }

    .rightNav {
      margin-left: auto;
      display: flex;
      list-style: none;
      transition: all .4s ease-in;

      @media only screen and (max-width: 767px) {
        display: none;
      }

      li {
        margin-right: 20px;

        @media only screen and (max-width: 991px) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          font-size: 14px;
          color: $blue;
          padding: 32px 12px;
          text-decoration: none;
          font-weight: 700;
          transition: all .4s ease;

          @media only screen and (max-width: 991px) {
            font-size: 13px;
            padding: 32px 8px;
          }

          &.active, &:hover {
            background: $blue_one;
            color: $orange;
          }
        }
      }

      &.hamburger {
        display: none;
        margin-right: 0;
        height: auto;
        overflow: unset;

        @media only screen and (max-width: 767px) {
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }

        li {
          display: inline-flex;

          a {
            background: transparent;
            max-width: 40px;
            padding: 20px 8px;
            display: block;
          }
        } 
      }

      &.open {
        overflow: unset;
        display: block;
        position: absolute;
        z-index: 9;
        top: 65px;
        left: 0;
        width: calc( 100% + 15px );
        background: #FFF;

        li {
          border-bottom: 1px solid rgba($color: #132e53, $alpha: 0.2);

          a {
            text-align: center;
            padding: 18px 8px;
          }
        }
      }
    }
  }
}