.signUpContainer {
  background: #FFF url("../../images/signUpBg.jpg") no-repeat;
  background-size: cover;
  padding: 30px 0;
  flex: 1;
  .modalFooter {
    margin: 20px 0;
  }
  .inner {
    background: url("../../images/signUp_container_bg.png") repeat;
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 15px 70px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border-radius: 4px;

    @media only screen and (max-width: 767px) {
      padding: 15px;
    }

    h1 {
      color: #20395c;
      font-size: 70px;
      padding-bottom: 20px;

      @media only screen and (max-width: 1023px) {
        font-size: 50px;
        padding-bottom: 10px;
      }

      @media only screen and (max-width: 1023px) {
        font-size: 40px;
      }
    }

    .header {
      color: #626262;
      font-size: 24px;
      font-weight: 700;
      padding-bottom: 35px;

      @media only screen and (max-width: 1023px) {
        font-size: 18px;
        padding-bottom: 20px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  .captchaContainer {
    padding-bottom: 25px;
    display: inline-block;
  }

  .social-btns {
    max-width: 310px;
    width: 100%;
    margin: 0 auto;

    .primary-btn {
      width: 100%;
      margin-bottom: 18px;
    }
  }

  .have-account,
  .terms {
    color: #626262;
    padding-bottom: 60px;

    @media only screen and (max-width: 767px) {
      padding-bottom: 20px;
    }
    
    a {
      color: #626262;
    }
  }

  .terms {
    padding-bottom: 0;
  }

  &.signin {
    .inner {
      max-width: 570px;

      .have-account {
        padding-bottom: 0;
      }
    }
  }
}

.primary-btn {
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-height: 48px;
  border-radius: 25px;
  background: #f58533;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  &:hover {
    background: #f58533;
  }

  &.blue {
    background: #132e53;

    &:hover {
      background: #132e53;
    }
  }

  img {
    margin-right: 12px;
  }
}
.joinError {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto 20px;
  line-height: 1em;
}