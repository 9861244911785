.faqContainer {
  background: url("../../images/faq_bg.jpg") no-repeat center;
  background-size: cover;
  padding: 145px 0 80px;
  margin-bottom: -20px;

  @media only screen and (max-width: 1023px) {
    padding: 30px 0;
  }

  .accordion {
    border: none;

    .accordion__item {
      font-size: 14px;
      font-weight: 700;
      color: #626262;
      background: #fff;
      box-shadow: 0 0 10px rgba(0,0,0,.15);
      margin-bottom: 22px;

      &:focus {
        outline: none;
      }

      + .accordion__item {
        border-top: none;
      }

      .accordion__heading {
        &:focus {
          outline: none;
        }
      }

      .accordion__button {
        color: #20395c;
        position: relative;
        padding: 15px 35px 15px 25px;
        background: #f5f5f5;

        &:focus {
          outline: none;
        }

        &:before {
          transform: rotate(45deg);
          position: absolute;
          right: 25px;
          margin-right: 0;
          top: 15px;
        }
      }

      .accordion__panel {
        padding: 25px;
      }
    }
  }

  .last-text {
    font-size: 14px;
    font-weight: 700;
    padding-top: 20px;
    
    a {
      color: #132e53;
    }
  }
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(-135deg) !important;
  top: 18px !important;
}