@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("./fonts/AntennaCond/stylesheet.css");
// colors that are being used
$orange: #f58533;

// Fonts to be used
// font-family: 'AntennaCond';
// font-family: 'Lato', sans-serif;

html {
  height: 100%;
  // overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
select,
textarea,
button {
  font-family: 'Lato', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
  overflow: hidden;
  color: #245995;
}

a:focus {
  color: #1D3796;
}
a[tabIndex]:focus {
  color: #1D3796;
  outline: none;
}
a:hover {
  cursor: pointer;
}

a:disabled {
  color: #b4c6dc;
}
button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
a[disabled] {
  pointer-events: none;
  color: #b4c6dc;
}
a[disabled] svg path {
  fill: #b4c6dc;
}

.m-v-50 {
  margin: 50px 0;
}

.m-v-20 {
  margin: 20px 0;
}

.m-top-20 {
  margin: 20px 0 0 0;
}

.text-center {
  text-align: center;
}

h1, h2, h3, h4, h5 {
  color: #245995;
}

h1 {
  font-size: 40px;
  
  @media only screen and (max-width: 1023px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 639px) {
    font-size: 24px;
  }
}

.container {
  max-width: 1200px !important;
  width: 100%;

  > span {
    display: none !important;
  }
}

.orange {
  color: $orange;
}