.baseContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}
.container {
  display: flex;
  align-items: stretch;
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 960px !important;
}

