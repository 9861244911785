.baseHeaderMobileMenu,
.baseHeaderMobileSearch {
  position: fixed;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  margin: 100px 0 0;
  padding: 77px 35px 75px;

  list-style: none;
  transition: all .3s ease 0s;
  overflow-y: scroll;
}
.visibleInfo,
.visibleMenu {
  top: 0;
}
.baseHeaderMobileMenu > li {
  margin-bottom: 55px;
}
.baseHeaderMobileMenu > li > a,
.baseHeaderMobileMenu > li > div {
  display: block;
  text-decoration: none;
  font-size: 20px;
  line-height: 26px;
  color: #454452;
}
.baseHeaderMobileMenu > li > a:hover,
.baseHeaderMobileMenu > li > div:hover,
.baseHeaderMobileMenu > li > a:focus {
  color: #245995;
}
.baseHeaderMobileMenu>.close {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px;
  margin: 0;
}
.baseHeaderMobileMenu .separator {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 52px;
}
.baseHeaderMobileSearch .baseHeaderMobileSearchContainer {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px 0;
}
.baseHeaderMobileSearchContainer > .close {
  padding: 12px;

  position: absolute;
  right: 0;
  top: 0;
}
.baseHeaderMobileSearchContainer > .searchContainer > input {
  width: 100%;
  padding: 10px;

  border: 1px solid #eee;
  border-radius: 15px;
  
  font-size: 14px;
}
.Button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ButtonLink {
  font-weight: 400;
  text-decoration: underline;
}
.separator {
  color: #245995;
  margin-left: 27px;
}
.Button {
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
  color: #29649f;
  padding: 32px 12px;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 700;
  transition: all .4s ease;
}
.selected,
.Button:hover {
  background: #132e53;
  color: #f58533;
}
.Button:focus {
  color: #f19b2e;
}

.userMenu {
  display: flex;
  flex-direction: column;

  top: 50px;
  right: -10px;
  z-index: 1;
  
  width: 275px;
  
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(103,105,118, 0.19);
}
.userMenu .separator {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 37px;
}
.userMenu a {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #454452;
  text-decoration: none;
  margin-bottom: 31px;
}
.userMenu a:last-child {
  margin-bottom: 0;
}
.userMenu a:hover,
.userMenu a:focus {
  color: #245995;
}
.userMenu>.menuContainer {
  height: 100px;
}
.logoContainer {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all .3s;
  margin-left: 16px;
}
.photo {
  height: 35px;
  width: 35px;

  border: 2px solid #0459FF;
  border-radius: 50%;

  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 991px) {	
    .ButtonLink,
    .Button {
      max-width: 136px;
      float: right;
      margin-left: 10px;
      line-height: 17px;
      letter-spacing: .40px;
      font-size: 14px;
      font-weight: 400;
      color: #245995;
    }
}
