.mainFooter {
  margin-top: 20px;
  background: #132e53 url("../../../images/footer_bg.jpg") no-repeat;
  padding-top: 53px;

  .footerLogo {
    background: #ffffff;
    display: flex;
    width: 280px;
    height: 65px;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin: 0 auto 40px;
  }

  .footerNav {
    display: flex;
    justify-content: center;
    list-style: none;
    font-weight: 700;
    padding-bottom: 32px;
    border-bottom: 1px solid #224b83;
    margin: 0 auto 20px;
    width: fit-content;

    @media only screen and (max-width: 640px) {
      padding-bottom: 10px;
      flex-wrap: wrap;
    }

    a {
      margin-right: 40px;

      @media only screen and (max-width: 640px) {
        margin-right: 10px;
        margin-left: 10px;
        font-size: 12px;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      color: #29649f;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;

      &:hover, &.active {
        border-color: #f5b151;
        color: #f5b151;
      }
    }
  }

  .footerContact {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #224b83;
    padding-bottom: 20px;
    list-style: none;
    text-transform: none;
    
    li {
      margin-right: 12px;
    }

    a {
      align-items: center;
      display: flex;
      color: #ffffff;
      text-transform: none;

      img {
        margin-right: 7px;
      }
    }
  }

  .footerBottom {
    font-size: 14px;

    .container {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      color: #ffffff;

      @media only screen and (max-width: 640px) {
        display: block;
      }

      a {
        color: #ffffff;
      }

      .copyRight {
        flex: 1;
        justify-content: center;
        text-align: center;
        padding: 0 15px;

        @media only screen and (max-width: 640px) {
          padding-bottom: 10px;
        }
      }

      .footerSocial {
        list-style: none;
        display: flex;
        flex: 1;
        
        @media only screen and (max-width: 640px) {
          justify-content: center;
          padding-bottom: 10px;
        }

        li {
          border-right: 1px solid #ffffff;
          
          a {
            padding: 0 10px;
          }

          &:last-child {
            border: none;
          }
        }
      }

      .terms {
        flex: 1;
        text-align: right;
        @media only screen and (max-width: 640px) {
          text-align: center;
        }
      }
    }
  }
}